import { io, Socket } from 'socket.io-client';
import { useAuth0 } from '@auth0/auth0-react';
import * as uuid from 'uuid';
import { getProject, useProjectState } from '../store/project';
import { useProductStoreState } from '../store/product';
import { useSelectedOrganization } from '../store/organization';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Project } from '../../../shared/models/project.interface';
import { useConfig } from '../providers/ConfigProvider';
import { useCommentsStore } from '../store/comment/comment.store';
import { IComment } from '../../../shared/models/comment.interface';
import { useRecipesStore } from '../store/recipe';
import { IProduct } from '../../../shared/models/product.interface';
import { UpdateClientData } from '../store/utils';

type SocketListenerUtils = () => [
  (socketListenersRegistered: MutableRefObject<boolean>) => Promise<void>,
  () => void,
];

export const useSocketListeners: SocketListenerUtils = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [config] = useConfig();
  const { updateProjectLocally } = useProjectState('updateProjectLocally');
  const { updateProductsLocally } = useProductStoreState(
    'updateProductsLocally',
  );
  const updateCommentsLocally = useCommentsStore(
    ({ updateCommentsLocally }) => updateCommentsLocally,
  );
  const updateRecipesLocally = useRecipesStore(
    ({ updateRecipesLocally }) => updateRecipesLocally,
  );

  const selectedOrganization = useSelectedOrganization();
  const socketRef = useRef<Socket | undefined>();

  useEffect(() => window.sessionStorage.setItem('id', uuid.v4()), []);

  const handleResource = useCallback(
    <T>(
      sessionId: string,
      event: 'add' | 'update' | 'remove',
      cb: (args: UpdateClientData<T>) => void,
    ) =>
      (resource: T, initiatorSessionId: string) => {
        if (initiatorSessionId === sessionId || isEmpty(resource)) {
          return;
        }
        event === 'remove'
          ? cb({ event, itemOrId: resource as string })
          : cb({ event, itemOrId: resource });
      },
    [],
  );

  const addSocketListeners = useCallback(
    async (socketListenersRegistered: MutableRefObject<boolean>) => {
      const token = await getAccessTokenSilently();
      const socket = io(config.baseUrl, {
        auth: {
          token: `Bearer ${token}`,
        },
        extraHeaders: {
          AppVersion: config.version,
        },
        query: {
          organization: selectedOrganization,
        },
        transports: ['websocket'],
      });
      socketRef.current = socket;
      const sessionId = window.sessionStorage.getItem('id') ?? '';

      socket.on(
        'project_update',
        handleResource<Project>(sessionId, 'update', updateProjectLocally),
      );
      socket.on(
        'project_delete',
        handleResource<string>(sessionId, 'remove', ({ itemOrId }) => {
          if (String(getProject().id) !== itemOrId) {
            return;
          }
          window.location.reload();
        }),
      );

      socket.on(
        'recipe_create',
        handleResource(sessionId, 'add', updateRecipesLocally),
      );
      socket.on(
        'recipe_update',
        handleResource(sessionId, 'update', updateRecipesLocally),
      );
      socket.on(
        'recipe_delete',
        handleResource(sessionId, 'remove', updateRecipesLocally),
      );

      socket.on(
        'product_create',
        handleResource<IProduct>(sessionId, 'add', updateProductsLocally),
      );
      socket.on(
        'product_update',
        handleResource<IProduct>(sessionId, 'update', updateProductsLocally),
      );
      socket.on(
        'product_delete',
        handleResource<IProduct>(sessionId, 'remove', updateProductsLocally),
      );

      socket.on(
        'comment_create',
        handleResource<IComment>(sessionId, 'add', updateCommentsLocally),
      );
      socket.on(
        'comment_update',
        handleResource<IComment>(sessionId, 'update', updateCommentsLocally),
      );
      socket.on(
        'comment_delete',
        handleResource<IComment>(sessionId, 'remove', updateCommentsLocally),
      );

      socketListenersRegistered.current = true;
    },
    [
      getAccessTokenSilently,
      config.baseUrl,
      config.version,
      selectedOrganization,
      handleResource,
      updateProjectLocally,
      updateRecipesLocally,
      updateProductsLocally,
      updateCommentsLocally,
    ],
  );

  const clearSocketListeners = useCallback(() => {
    if (socketRef.current) {
      socketRef.current.removeAllListeners();
    }
  }, [socketRef]);

  return [addSocketListeners, clearSocketListeners];
};
