import React, { useCallback, useEffect, useMemo } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AppBar from '../components/AppBar';
import { Routes, Route, useMatch, useNavigate } from 'react-router-dom';
import CreateProjectView from './CreateProjectView';
import ProviderErrors from '../components/ProviderErrors';
import SidePanel from '../components/SidePanel/SidePanel';
import { useProjectState } from '../store/project';
import { useUIState } from '../store/ui';
import { Box } from '@mui/system';
import { useNodonNavigation } from '../hooks/router.hooks';
import {
  NODON_SHARED_URL_PATH_FULL_PATTERN,
  NODON_URL_PATH_FULL_PATTERN,
} from '../../../shared/constants/router.constants';
import { useCloseExpressionInputPanel } from '../components/ExpressionInputPanel';
import ProjectSelector from '../components/project-selector/ProjectSelector';
import ProjectView from './ProjectView';
import Loading from '../components/Loading';
import { useIsLoading } from '../hooks/useIsLoading';
import { useGlobalKeyListener } from '../hooks/useGlobalKeyListener';
import { useSelectedOrganization } from '../store/organization';
import { useProductStoreState } from '../store/product';
import { useUserStore } from '../store/user/user.store';
import { useRecipesStore } from '../store/recipe';
import { useInitUndoRedo } from '../hooks/undo-redo.hook';
import { isNotFoundError } from '../components/AxiosConfig';
import { Project } from '../../../shared/models/project.interface';
import { useHasExpandedElementVersions } from '../hooks/element-version.hook';
import { useMouseEventCallback } from '../hooks/events.hook';
import { useCommentsStore } from '../store/comment/comment.store';
import { SidePanelStyles } from '../style/constants';

const useStyles = makeStyles()(({ spacing }: Theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
  },

  base: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: `auto ${SidePanelStyles.WIDTH}px`,
    '@media print': {
      gridTemplateColumns: 'auto auto',
    },
  },

  menuButton: {
    marginRight: spacing(2),
  },

  contentContainer: {
    marginTop: '64px',
    height: `calc(100vh - 64px)`,
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
  },

  content: {
    flexGrow: 1,
    height: '100%',
    overflow: 'auto',
    '@media print': {
      overflow: 'hidden',
    },
  },
}));

const notFoundIds: Project['id'][] = [];

const MainView: React.FC = () => {
  useGlobalKeyListener();
  const { classes } = useStyles();

  const fetchRecipes = useRecipesStore.getState().fetchRecipes;
  const fetchUsers = useUserStore.getState().fetchUsers;
  const fetchComments = useCommentsStore.getState().fetchComments;

  const {
    projectsLookup,
    project: selectedProject,
    fetchProject,
  } = useProjectState('projectsLookup', 'project', 'fetchProject');

  const { fetchProducts } = useProductStoreState('fetchProducts');

  const { clearSelections, expressionInputPanelError, showProjectSelector } =
    useUIState(
      'clearSelections',
      'expressionInputPanelError',
      'showProjectSelector',
    );

  const closeExpressionInputPanel = useCloseExpressionInputPanel();
  const navigate = useNavigate();

  const selectedOrganization = useSelectedOrganization();
  const loading = useIsLoading();
  const sharedMatch = useMatch(NODON_SHARED_URL_PATH_FULL_PATTERN);
  const urlParams = useNodonNavigation();
  const expandedElementVersion = useHasExpandedElementVersions();

  useInitUndoRedo();

  const projectId = useMemo(
    () =>
      urlParams?.projectId ? parseInt(urlParams.projectId, 10) : undefined,
    [urlParams],
  );

  const project = useMemo(
    () =>
      typeof projectId === 'number' && isFinite(projectId)
        ? projectsLookup[projectId]
        : undefined,
    [projectId, projectsLookup],
  );

  useEffect(() => {
    if (
      /* Selected organization is sometimes initially undefined after saving certain files,
      which will crash the app when the store tries to fetch the project. */
      selectedOrganization &&
      projectId &&
      projectId !== selectedProject.id
    ) {
      fetchProject(projectId).catch((e) => {
        // If 404 error, try to navigate ONCE to "/". Keep track on projectId to avoid infinite loop
        if (isNotFoundError(e) && !notFoundIds.includes(projectId)) {
          notFoundIds.push(projectId);
          navigate('/');
        }
      });
    }
  }, [
    fetchProject,
    navigate,
    selectedOrganization,
    projectId,
    selectedProject.id,
  ]);

  useEffect(() => {
    if (projectId) {
      fetchComments(projectId);
      fetchProducts();
      fetchUsers();
      fetchRecipes();
    }
  }, [fetchComments, fetchRecipes, fetchUsers, fetchProducts, projectId]);

  const handleMainViewClick = useCallback(() => {
    closeExpressionInputPanel();
  }, [closeExpressionInputPanel]);

  const handleEditProjectViewClick: React.MouseEventHandler<HTMLElement> =
    useMouseEventCallback(
      () => {
        closeExpressionInputPanel();

        if (!expressionInputPanelError) {
          clearSelections(
            'selectedElement',
            'selectedElementCategoryId',
            'selectedProductListElementId',
          );
        }
      },
      { ignoreModalEvents: true, ignoreInputEvents: true },
    );

  const appBar = useMemo(
    () => <AppBar key={'appbar'} project={project} />,
    [project],
  );

  const sidebar = useMemo(() => <SidePanel panelPosition="right" />, []);

  if (!selectedOrganization) {
    return null;
  }

  return (
    <div className={classes.root} onClick={handleMainViewClick}>
      {appBar}
      <Box className={classes.base}>
        <div className={classes.contentContainer}>
          <main
            className={classes.content}
            onClick={handleEditProjectViewClick}
            style={expandedElementVersion ? SELECTED_BG : undefined}
          >
            <Routes>
              <Route path="/projects/new" element={<CreateProjectView />} />
              <Route
                path={sharedMatch ? '*' : NODON_URL_PATH_FULL_PATTERN}
                element={
                  <>
                    {showProjectSelector && <ProjectSelector />}
                    {!showProjectSelector && <ProjectView />}
                  </>
                }
              />
            </Routes>
            <ProviderErrors />
          </main>
        </div>

        {sidebar}
      </Box>

      <Loading open={loading} />

      {/* <ExportDialog
        open={exportDialogOpen && !!project}
        setOpen={setExportDialogOpen}
        onExport={(fileName: string) => {
          console.error(
            'NOT IMPLEMENTED EXPORT: Export has been triggered with name',
            fileName,
          );
        }}
        projectName={project?.name ?? ''}
      /> */}
    </div>
  );
};

const SELECTED_BG = { backgroundColor: 'rgba(0,0,0,0.04)' } as const;

export default MainView;
