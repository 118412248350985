import { useProductStoreState } from '../store/product';
import { useProjectState } from '../store/project';

export const useIsLoading = (): boolean => {
  const { projectsFetching, isLoading, isCreating, isDeleting } =
    useProjectState(
      'projectsFetching',
      'isLoading',
      'isCreating',
      'isDeleting',
    );
  const { isLoading: productsLoading, isUpdating: productsUpdating } =
    useProductStoreState('isLoading', 'isUpdating');

  return (
    projectsFetching ||
    isLoading ||
    isCreating ||
    isDeleting ||
    productsLoading ||
    productsUpdating
  );
};
