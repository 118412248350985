import { Switch, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import { useUIState } from '../../store/ui';
import { createLocalStorageStore } from '../../helpers/local-storage.helpers';

export const { useStore: useGroupByCategory, set: setGroupByCategory } =
  createLocalStorageStore('group_by_category', false);

const GroupByCategorySwitch = () => {
  const { selectedPage } = useUIState('selectedPage');
  const groupByCategory = useGroupByCategory();

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setGroupByCategory(checked);
    },
    [],
  );

  return (
    <Tooltip title="Group by category">
      <Switch
        size="small"
        checked={groupByCategory}
        disabled={selectedPage !== 'products'}
        onChange={handleChange}
      />
    </Tooltip>
  );
};

export default GroupByCategorySwitch;
