import {
  ElementCategoryID,
  IElementCategory,
} from '../../models/element_categories.interface';
import { slab } from './slab';
import { wall } from './wall';
import { column } from './column/column';
import { beam } from './beam/beam';
import { maintenance } from './maintenance';
const other: IElementCategory = {
  id: ElementCategoryID.Other,
  name: 'Other',
};

// Will appear in order in UI
export const systemCategories: IElementCategory[] = [
  beam,
  column,
  slab,
  wall,
  maintenance,
  other,
];
