import {
  OneOfElements,
  IProductElement,
  ElementKind,
  IBuilding,
  IElement,
  OneOfChildElements,
  IBuildingVersion,
  OneOfParentElements,
  ICountAndUnit,
  IRecipeElement,
  Project,
  IElementID,
  ExpressionValue,
  OneOfPropertyElements,
  OneOfElementListElements,
  OneOfListElements,
  ElementKindMap,
  OneOfProductListChildren,
} from '../models/project.interface';
import {
  EMPTY_ARRAY,
  asArray,
  includesSome,
  isDefined,
  isOneOf,
  typeFilter,
} from './array_helpers';
import { cloneDeep, escapeRegExp, isObject, last } from 'lodash';
import {
  isElementExpressionProperty,
  getElementProperties,
} from './element_property_helpers';
import {
  ArrayOrSingle,
  ItemOrItemId,
  NonNullableRequired,
  OptionallyRequired,
} from '../models/type_helpers.interface';
import { PathRecord } from './element_category_helpers';
import { getElementQuantityProperties } from './element_quantity_helpers';
import { Recipe } from '../models/recipe.interface';
import { isRecipe } from './recipe_helpers';
import {
  getId,
  hasDefinedProperties,
  isNonArrayObject,
} from './object_helpers';
import { isProjectInfoOrFolder } from './project-folder.helpers';
import { IElementProperty } from '../models/element_property.interface';
import { isExpressionValue } from './expression_factory_helpers';
import { cacheFactory, required } from './function_helpers';
import { requiredKind } from './element_helpers';
import { createElementFromPartial } from './element_factory_helpers';
import { validateProject } from '../validation';
import {
  IProductListCategoryGroup,
  IProductListGroup,
  IProductListItem,
} from '../models/product.interface';
import { TMP_ELEMENT_ID } from '../constants';

export type ElementPath = [OneOfParentElements, ...IElement[]] | [];

export type OneOfSearchableElements =
  | OneOfElements
  | IBuilding
  | Project
  | Recipe;

type FindFn<T> = (
  el: T,
  path: OneOfParentElements[],
) => boolean | void | undefined;
type ForEachFn<T> = (el: T, path: ElementPath) => any;

const getFindFn = <T extends OneOfElements = OneOfElements>(
  fnOrId: FindFn<T> | IElementID,
): FindFn<T> => {
  if (typeof fnOrId === 'string') {
    // Reuse find function to avoid creating new functions and invalidating other caches
    return cacheFactory(
      () => (el: T) => el.id === fnOrId,
      `getFindFn[${fnOrId}]`,
      [fnOrId],
    );
  }
  return fnOrId;
};

/**
 * Recursive find function works like Array.find
 * but for all elements in element tree. Will include root element (buildingVersion) in the search
 * @param searchIn
 * @param fnOrId Function to find element or id to search for
 * @returns
 */
export const findElement = (
  searchIn: OneOfSearchableElements | undefined,
  fnOrId: FindFn<OneOfElements> | IElementID | undefined,
  path: OneOfParentElements[] = [],
): OneOfElements | undefined => {
  if (!searchIn) {
    return undefined;
  }
  if (!fnOrId) {
    return undefined;
  }
  const fn: FindFn<OneOfElements> = getFindFn(fnOrId);

  if (isOneOfElements(searchIn) && fn(searchIn, path)) {
    return searchIn;
  } else {
    const children = getSearchableChildren(searchIn);
    const childPath = isOneOfParentElements(searchIn)
      ? [...path, searchIn]
      : path;

    // Use a for loop instead of forEach to avoid nested functions
    // Otherwise "return" will not work properly
    for (const child of children) {
      // Search in the current child
      const result = findElement(child, fn, childPath);

      // Abort search if the element has been found
      if (result !== undefined) {
        return result;
      }
    }
  }
};

interface IElementPathAndParent {
  element?: OneOfElements;
  path: OneOfParentElements[];
  parent?: OneOfParentElements;
}

export const findElementAndParent = <
  T extends boolean = false,
  R = T extends true
    ? NonNullableRequired<IElementPathAndParent>
    : IElementPathAndParent,
>(
  searchIn: OneOfSearchableElements | undefined,
  fnOrId: FindFn<OneOfElements> | IElementID,
  require: T = false as T,
): R => {
  const fn = getFindFn(fnOrId);
  let path: OneOfParentElements[] = [];

  const element = findElement(searchIn, (el, p) => {
    if (fn(el, p)) {
      path = p;
      return true;
    }
  });

  return {
    element: required(element, require),
    path,
    parent: required(last(path), require),
  } as R;
};

export const getBuilding = (project: Project): IBuilding => {
  const building = project.buildings[0];

  if (!building) {
    throw new Error('no building found');
  }
  return building;
};

/**
 * Recursive find function works like Array.find
 * but for all elements in element tree including searchIn element.
 * Will include root element (like buildingVersion) in the search
 * @param searchIn
 * @param fn
 * @returns
 */
export const forEachElement = (
  searchIn: ArrayOrSingle<OneOfSearchableElements> | undefined,
  fn: ForEachFn<OneOfElements>,
  path: ElementPath = [],
): void => {
  if (!searchIn) {
    return;
  }
  // Support array of elements by just rerunning the function for each element
  if (Array.isArray(searchIn)) {
    return searchIn.forEach((el) => forEachElement(el, fn, path));
  }

  if (isOneOfElements(searchIn)) {
    fn(searchIn, path);
  }
  const childPath = isOneOfParentElements(searchIn)
    ? [...path, searchIn]
    : path;
  getSearchableChildren(searchIn).forEach((e) =>
    forEachElement(e, fn, childPath as ElementPath),
  );
};

/**
 * Filter elements recursively. Will include root element (buildingVersion) in the search
 * @param searchIn
 * @param fn
 * @param searchExcludedChildren If false, children of excluded elements will not be searched
 * @returns
 */
export const filterElements = (
  searchIn: ArrayOrSingle<OneOfSearchableElements>,
  fn: FindFn<OneOfElements>,
  searchExcludedChildren = true,
): OneOfElements[] => {
  const elements: OneOfElements[] = [];
  const excludedElements: OneOfElements[] = [];

  forEachElement(searchIn, (el, path) => {
    // If a parent element is excluded, don't search children (unless searchExcludedChildren is true)
    if (!searchExcludedChildren && includesSome(excludedElements, ...path)) {
      return;
    }

    if (fn(el, path)) {
      elements.push(el);
    }
    // Store excluded elements to avoid searching children
    else {
      excludedElements.push(el);
    }
  });

  return elements;
};

/**
 * Get all parents of a certain element as an array (excluding self).
 * First item in path is a IBuildingVersion if searchIn is a project, building or version.
 * Return undefined if element is not found.
 * @param root
 * @param elementOrId
 * @returns
 */
export const getPathToElement = <
  T extends boolean = true,
  R = OptionallyRequired<ElementPath, T>,
>(
  searchIn: OneOfSearchableElements | ElementPath,
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
  throwIfNotFound: T = true as T,
): R => {
  // If a path is passed in, return it
  if (Array.isArray(searchIn)) {
    return searchIn as R;
  }
  let currentPath: OneOfParentElements[] | undefined;
  if (elementOrId) {
    const elementId = getId(elementOrId);
    findElement(searchIn, (e, path) => {
      // Find by id to avoid comparing objects (will be more tolerant to changes)
      if (e.id === elementId) {
        currentPath = path;
        return true;
      }
    });
  }
  if (throwIfNotFound && !currentPath) {
    throw new Error('Could not get path of element not found');
  }
  return currentPath as R;
};

/**
 * Get element in tree structure by id
 * @param searchIn Element to search in
 * @param id Id to search for
 * @param require Pass true to throw if element is not found. Pass a kind to require a specific kind of element
 * @returns
 */
export const getElementById = <
  T extends boolean | keyof ElementKindMap = false,
  R = T extends ElementKind
    ? ElementKindMap[T]
    : OptionallyRequired<OneOfElementListElements, T>,
>(
  searchIn: OneOfSearchableElements | undefined,
  id: OneOfElements['id'] | undefined,
  require: T = false as T,
): R => {
  const element = id ? findElement(searchIn, id) : undefined;

  if (typeof require === 'string') {
    return requiredKind(element, require) as R;
  }
  return required(element, require);
};

/**
 * Similar to getElementById but also returns the path to the element
 * @param searchIn Which element to search in
 * @param id optional id to search for
 * @param requireResult If true, will throw if element is not found
 * @returns
 */
export const getPathAndElementById = <T extends boolean = false>(
  searchIn: OneOfSearchableElements | undefined,
  id: OneOfElements['id'] | undefined,
  requireResult: T = false as T,
) => findElementAndParent(searchIn, required(id, requireResult), requireResult);

/**
 * Get parent of an element if it exists (versions won't have a parent)
 * @param searchIn
 * @param element
 * @returns
 */
export const getParentElement = (
  searchIn: OneOfSearchableElements,
  elementOrId: ItemOrItemId<OneOfElements> | undefined,
): OneOfParentElements | undefined =>
  elementOrId ? last(getPathToElement(searchIn, elementOrId)) : undefined;

export const getBuildingVersionFromChild = (
  project: Project | IBuilding | IBuildingVersion,
  child: OneOfElements,
): IBuildingVersion => {
  const version = getPathToElement(project, child)[0];
  if (!isBuildingVersionElement(version)) {
    throw new Error('Could not find building version for child');
  }
  return version;
};

/**
 * Get Main Category of an element (recursive)
 * @param version
 * @param element
 * @returns
 */
export const getMainCategoryElementAncestor = <T extends OneOfElements>(
  version: IBuildingVersion,
  element: T | undefined,
): IElement | undefined => {
  const parent = getParentElement(version, element);

  if (isElement(parent) && parent?.category_id) {
    return parent;
  } else if (
    isBuildingVersionElement(element) ||
    isBuildingVersionElement(parent) ||
    !parent
  ) {
    return undefined;
  } else {
    return getMainCategoryElementAncestor(version, parent);
  }
};

/**
 * Get all elements with children in a flat array.
 * Will be in the order of the element list ()
 * @param elements
 * @returns
 */
export const flattenElements = <
  T extends OneOfSearchableElements,
  R = T extends OneOfChildElements ? OneOfChildElements : OneOfElements,
>(
  ...elements: (T | undefined)[]
): R[] => {
  const searchIn = getSearchRoot(...elements);
  const elementsWithChildren = searchIn.map((element) => [
    element,
    ...flattenElements(...getChildElements(element)),
  ]);

  return elementsWithChildren.flat() as R[];
};

export const getAllProductElements = (
  ...element: (OneOfSearchableElements | undefined)[]
): IProductElement[] => {
  return flattenElements(...element).filter(isProductElement);
};

export const getAllRecipeElements = (
  recipe?: Recipe,
  ...element: OneOfSearchableElements[]
): IElement[] => {
  return flattenElements(...element)
    .filter(isElement)
    .filter((recipeElement) => recipeElement.recipe_id === recipe?.id);
};

/**
 * Helper to avoid having to if case products all the time
 * @param element
 * @returns
 */
export const isParentElement = <T extends OneOfListElements = IElement>(
  element: OneOfSearchableElements | OneOfListElements | undefined,
): element is OneOfParentElements<T> => {
  return !!element && 'elements' in element && !!element.elements;
};

export const isOneOfParentElements = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is OneOfParentElements => {
  return isElement(element) || isBuildingVersionElement(element);
};

export const isOneOfElements = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is OneOfElements => {
  return isOneOfChildElements(element) || isBuildingVersionElement(element);
};

export const isOneOfChildElements = (
  element?: OneOfSearchableElements | OneOfListElements,
): element is OneOfChildElements => {
  return (
    isElement(element) ||
    isProductElement(element) ||
    isProjectInfoOrFolder(element)
  );
};

export const isOneOfPropertyElements = (
  element?: OneOfSearchableElements,
): element is OneOfPropertyElements =>
  isElement(element) || isBuildingVersionElement(element);

export const isProject = (element?: unknown): element is Project => {
  return (
    isObject(element) &&
    hasDefinedProperties(
      element as Project,
      'buildings',
      'name',
      'id',
      'owner',
    ) &&
    Array.isArray((element as Project).buildings)
  );
};

export const isBuilding = (
  element?: OneOfSearchableElements,
): element is IBuilding => {
  return (
    isObject(element) &&
    'versions' in element &&
    Array.isArray(element.versions)
  );
};

export const isElementKind = (kind?: unknown): kind is ElementKind => {
  return typeof kind === 'string' && isOneOf(ElementKind, kind);
};

export const isBuildingVersionElement = (
  element?: unknown,
): element is IBuildingVersion => {
  return getElementKind(element) === ElementKind.Version;
};

export const isProductElement = (
  element?: unknown,
): element is IProductElement => {
  return getElementKind(element) === ElementKind.Product;
};

export const isRecipeElement = (
  element?: OneOfElements,
): element is IRecipeElement => {
  return isElement(element) && !!element.recipe_id;
};

export const isElement = (element?: unknown): element is IElement =>
  getElementKind(element) === ElementKind.Element;

export const isTemporaryElement = (element?: unknown): element is IElement =>
  isElement(element) && element.id === TMP_ELEMENT_ID;

/**
 * TODO: Check if element have count and unit
 * @param element
 * @returns
 */
export const isCountAndUnitElement = <
  T extends OneOfElementListElements | ICountAndUnit | IElementProperty,
>(
  element?: T,
): element is T & Required<ICountAndUnit> => {
  return (
    hasDefinedProperties(element as ICountAndUnit, 'count', 'unit') &&
    isExpressionValue((element as ICountAndUnit).count)
  );
};

export const isProductListCategoryGroup = (
  element: unknown,
): element is IProductListCategoryGroup =>
  getElementKind(element) === ElementKind.ProductCategory;

export const isProductListGroup = (
  element: unknown,
): element is IProductListGroup =>
  getElementKind(element) === ElementKind.ProductGroup;

export const isProductListItem = (
  element: unknown,
): element is IProductListItem =>
  getElementKind(element) === ElementKind.ProductItem;

export const isProductListElement = (
  element: unknown,
): element is OneOfProductListChildren =>
  isProductListCategoryGroup(element) ||
  isProductListGroup(element) ||
  isProductListItem(element);

/**
 * Get root children of all versions
 * @param versions
 * @returns
 */
export const getChildElementsOfVersions = (
  ...versions: IBuildingVersion[]
): IElement[] => {
  return versions.reduce((elements, version) => {
    // TODO: Now it should only be IElement as childs of Versions.
    elements.push(...getChildElements(version).filter(isElement));
    return elements;
  }, [] as IElement[]);
};

/**
 * Helper to avoid having to if case products all the time
 * @param element
 * @returns
 */
export const getChildElements = <T extends OneOfListElements>(
  element?: T,
): OneOfChildElements<T>[] => {
  return (
    (element &&
      isParentElement<T>(element) &&
      (element.elements as OneOfChildElements<T>[])) ||
    []
  );
};

export const hasChildren = (element?: OneOfListElements): boolean =>
  getChildElements(element).length > 0;

/**
 * Get root elements that can be used by findElements/flattenElements etc.
 * @param element
 * @returns
 */
const getSearchRoot = (
  ...elements: (OneOfSearchableElements | undefined)[]
): OneOfElements[] =>
  elements
    .filter(isDefined)
    .map((element) => {
      if (isRecipe(element)) {
        return element.elements ?? [];
      }
      return isOneOfElements(element)
        ? [element]
        : getAllBuildingVersions(element);
    })
    .flat();

/**
 * Get children of element, if element is a building or project it will return all versions
 * @param element
 * @returns
 */
const getSearchableChildren = (
  element: OneOfSearchableElements | undefined,
): OneOfElements[] => {
  if (isRecipe(element)) {
    return element.elements ?? [];
  }
  return isOneOfElements(element)
    ? getChildElements(element)
    : getAllBuildingVersions(element);
};

/**
 * Get elements who is parent from the list of elements, IE. Ignoring products
 * Note that it filters on type so an empty array also counts as children.
 * @param element
 */
export const getChildrenWithChildren = (
  element: OneOfElements,
): OneOfParentElements[] => {
  return typeFilter(getChildElements(element), isParentElement);
};

export const getAllBuildingVersions = (
  versionParent:
    | Project
    | IBuilding
    | IBuildingVersion[]
    | IBuildingVersion
    | undefined,
): IBuildingVersion[] => {
  if (!versionParent) {
    return EMPTY_ARRAY as IBuildingVersion[];
  }
  if (Array.isArray(versionParent)) {
    return versionParent;
  }
  if (isBuildingVersionElement(versionParent)) {
    return [versionParent];
  }

  const buildings = isBuilding(versionParent)
    ? [versionParent]
    : (versionParent.buildings ?? []);

  const versionsInBuildings = buildings.map((building) => building.versions);
  const firstVersion = versionsInBuildings[0];

  // Use original array to avoid creating new array if possible, better for useMemo etc
  return versionsInBuildings.length === 1 && firstVersion
    ? firstVersion
    : versionsInBuildings.flat();
};

export const getBuildingVersionById = <
  T extends true | false | undefined = false,
  R = T extends true ? IBuildingVersion : IBuildingVersion | undefined,
>(
  projectOrBuilding: Project | IBuilding | IBuildingVersion[] | undefined,
  id: IElementID | undefined,
  throwIfNotFound: T = false as T,
): R => {
  const version = getAllBuildingVersions(projectOrBuilding).find(
    (v) => v.id === id,
  );
  if (throwIfNotFound && !version) {
    throw new Error('Could not find version');
  }
  return version as R;
};

/**
 * Regenerate ids for all elements in a project.
 * Will also remap selections in proposals
 * @param root A project, building or version to regenerate ids in
 * @returns
 */
export const regenerateIds = (project: Project): Project => {
  return validateProject({
    ...project,
    buildings: project.buildings.map((building) => {
      return {
        ...building,
        versions: building.versions.map((version) => {
          return createElementFromPartial(version, true);
        }),
      };
    }),
  });
};

/**
 *
 * @param versions
 */
export const regenerateIdsInVersion = (
  version: IBuildingVersion,
): IBuildingVersion => {
  const clone = cloneDeep(version);

  return clone;
};

export const getElementKind = (element?: unknown): ElementKind | undefined =>
  isNonArrayObject(element) &&
  'kind' in element &&
  typeof element.kind === 'string'
    ? (element.kind as ElementKind)
    : undefined;

export const getAllElementExpressionValues = (
  root: OneOfSearchableElements,
): ExpressionValue[] => {
  const allExpressionValues: ExpressionValue[] = [];

  flattenElements(root).forEach((element: OneOfElements) => {
    if ('count' in element && element.count && 'expression' in element.count) {
      allExpressionValues.push(element.count);
    }
    [...getElementProperties(element), ...getElementQuantityProperties(element)]
      .filter(isElementExpressionProperty)
      .forEach(({ count, fallbackCount }) => {
        if (count !== undefined) {
          allExpressionValues.push(count);
        }
        if (fallbackCount !== undefined) {
          allExpressionValues.push(fallbackCount);
        }
      });
  });

  return allExpressionValues;
};

export const getReplacedExpression = (
  expression: string,
  variablesToReplace: string[],
  replacement: string,
): string =>
  expression.replaceAll(
    new RegExp(
      `(?<=[^a-zåäö0-9_]|^)(${variablesToReplace
        .map(escapeRegExp)
        .join('|')})(?=[^a-zåäö0-9_]|$)`,
      'ig',
    ),
    replacement,
  );

/**
 * Replace variables in expressions.
 * Note: This will modify the project in place so be careful
 * @param project
 * @param replacements
 */
export const replaceExpressionVariables = (
  project: Project | Recipe,
  replacements: { variablesToReplace: string[]; replacement: string }[],
): void => {
  const allElementExpressionValues = getAllElementExpressionValues(project);

  replacements.forEach(({ variablesToReplace, replacement }) =>
    allElementExpressionValues.forEach((value) => {
      value.expression = getReplacedExpression(
        value.expression,
        variablesToReplace,
        replacement,
      );
    }),
  );
};

/**
 * Get how many levels of children an element have
 * @param element
 * @returns
 */
export const getElementChildrenDepth = (element?: OneOfElements): number => {
  let depth = 0;
  forEachElement(element, (_el, path) => {
    depth = Math.max(depth, path.length);
  });
  return depth;
};

/**
 * Get the path down to each product element
 * @param rootElements One or more elements to search from
 * @param getPathToAllElements Also get path for root elements (except version elements)
 * @returns
 */
export const getProductElementPathsRecord = (
  rootElements: ArrayOrSingle<OneOfElements> | undefined,
  getPathToAllElements = false,
): PathRecord => {
  const record = {} as Record<IElementID, OneOfParentElements[]>;

  asArray(rootElements).forEach((root) => {
    forEachElement(root, (el, path) => {
      const hasProductElements = flattenElements(el).some(isProductElement);
      const isRootElement =
        !isBuildingVersionElement(el) && !hasProductElements;

      if ((getPathToAllElements && isRootElement) || isProductElement(el)) {
        record[el.id] = path;
      }
    });
  });

  return record;
};

export const getBuildingVersionFromPath = (
  path: ElementPath | IBuildingVersion,
): IBuildingVersion =>
  isBuildingVersionElement(path)
    ? path
    : requiredKind(path[0], ElementKind.Version);

const isValidElementPath = (
  path: OneOfElements[] | ElementPath,
): path is ElementPath => {
  if (path.length === 0) {
    return true;
  }
  return path.every((e, i) => {
    const inParent = i > 0 ? isOneOf(getChildElements(path[i - 1]), e) : true;
    return (
      inParent &&
      (i === 0 ? isBuildingVersionElement(e) || isElement(e) : isElement(e))
    );
  });
};

export const validatePath = (
  path: OneOfElements[] | ElementPath,
): ElementPath => {
  if (!isValidElementPath(path)) {
    throw new Error('Invalid path');
  }
  return path;
};
