import {
  ElementCategoryID,
  IElementCategory,
} from '../../../models/element_categories.interface';
import {
  ElementPropertyInputType,
  IFactorySelectProperty,
} from '../../../models/element_property.interface';
import {
  DEFAULT_QUANTITY_EXPRESSIONS,
  DEFAULT_QUANTITY_PROPERTIES,
} from '../../../models/element_quantities.interface';
import { createProductTreeProcessor } from '../processor';
import genericProducts from '../../../generic_products';
import { ceramicsProductTree } from '../ceramics/ceramics.model';
import { gypsumProductTree } from '../gypsum/gypsum.model';
import { insulationProductTree } from '../insulation/insulation.model';
import { metalProductTree } from '../metal/metal.model';
import { windowsDoorsProductTree } from '../windows_doors/windows_doors.model';
import { woodProductTree } from '../wood/wood.model';
import {
  concreteQualityRecord,
  concreteTypeRecord,
} from '../concrete/concrete.model';
import { getIDsFromProductTree } from '../../../helpers/product_helpers';
import { sortBy } from 'lodash';
import { energyProducts } from '../energy';
import { OTHER_PRODUCT_ID } from '../../../constants';
import { omit } from '../../../helpers/object_helpers';
import { reusedContentProductId } from '../processor.model';

export const otherNodonProduct = genericProducts.find(
  (product) => product.id === OTHER_PRODUCT_ID,
);

const categoryProductIds =
  // getIDsFromProductTree is initially undefined when spinning up the server (npm run dev)
  getIDsFromProductTree
    ? [
        ...getIDsFromProductTree({ concreteQualityRecord, concreteTypeRecord }),
        ...getIDsFromProductTree(ceramicsProductTree),
        ...getIDsFromProductTree(gypsumProductTree),
        ...getIDsFromProductTree(insulationProductTree),
        ...getIDsFromProductTree(metalProductTree),
        ...getIDsFromProductTree(windowsDoorsProductTree),
        ...getIDsFromProductTree(woodProductTree),
        ...energyProducts.map(({ id }) => id),
      ]
    : [];

// find Boverket products that are not in other categories or climate improved
const filteredBoverketProducts = genericProducts.filter(
  ({ source, id, name }) => {
    const nameWithNoSpacesOrSpecialChars = name
      .replace(/\s+/g, '')
      .replace(/[^\w\s]/gi, '');

    return (
      source === 'Boverket' &&
      id !== reusedContentProductId &&
      !categoryProductIds.includes(id) &&
      !nameWithNoSpacesOrSpecialChars.includes('climateimproved')
    );
  },
);

const sorted = sortBy(filteredBoverketProducts, 'name');

const otherProducts = otherNodonProduct
  ? [otherNodonProduct, ...sorted]
  : sorted;

const selectProperty: IFactorySelectProperty = {
  name: 'products',
  type: ElementPropertyInputType.Select,
  count: otherProducts[0]?.name,
  options: otherProducts.map(({ name }) => name),
};

const processor = createProductTreeProcessor({
  levelProperties: [selectProperty],
  productTree: otherProducts.reduce(
    (acc, { id, name }) => ({ ...acc, [name]: id }),
    {},
  ),
});

export const otherProductCategory: IElementCategory = {
  ...processor,
  id: ElementCategoryID.OtherProduct,
  name: 'Other',
  getQuantityProperties: (element) => {
    const productElements = element && processor.getChildElements(element);

    const product = productElements?.length
      ? genericProducts.find((p) => p.id === productElements[0]?.product_id)
      : undefined;

    return product?.conversion_factors['m²']
      ? {
          ...omit(DEFAULT_QUANTITY_PROPERTIES, 'density'),
          density_areal_side: {
            fallbackCount: DEFAULT_QUANTITY_EXPRESSIONS.density_areal_side,
          },
        }
      : DEFAULT_QUANTITY_PROPERTIES;
  },
};
