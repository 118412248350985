import axios from 'axios';
import { devtools } from 'zustand/middleware';
import {
  IComment,
  ICommentID,
} from '../../../../shared/models/comment.interface';
import equal from 'fast-deep-equal';
import { ProjectID } from '../../../../shared/models/project.interface';
import {
  getErrorMessage,
  UpdateClientData,
  updateResourceLocally,
} from '../utils';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

type Error = { message: string; id?: string };

type UpdateCommentClientData = UpdateClientData<IComment> & {
  options?: Partial<Pick<CommentsState, 'isLoading' | 'editingId' | 'error'>>;
};

export interface CommentsState {
  isLoading: boolean;
  error: Error | undefined;
  comments: IComment[];

  fetchComments: (id: ProjectID) => Promise<void>;
  createComment: (comment: IComment, errorId: string) => Promise<void>;
  updateComment: (comment: IComment) => Promise<void>;
  deleteComment: (id: ICommentID) => Promise<void>;

  /** Util to update comments on client only */
  updateCommentsLocally: (args: UpdateCommentClientData) => void;

  scrollCommentsIntoView: boolean;
  setScrollCommentsIntoView: (value: boolean) => void;

  editingId: ICommentID | undefined;
  setEditingId: (id?: ICommentID) => void;
}

export const useCommentsStore = createWithEqualityFn<CommentsState>()(
  devtools(
    (set, get) => ({
      editingId: undefined,
      error: undefined,
      isLoading: false,
      scrollCommentsIntoView: false,
      comments: [],

      fetchComments: async (projectId) => {
        const { comments: currentComments } = get();

        set(() => ({ error: undefined, isLoading: true, comments: [] }));

        try {
          const { data } = await axios.get<IComment[]>('/comments', {
            params: { projectId },
          });

          if (!equal(currentComments, data)) {
            set(() => ({ comments: data }));
          }
          set(() => ({ isLoading: false }));
        } catch {
          const message = getErrorMessage('get', 'comments');

          set(() => ({ error: { message }, isLoading: false }));

          throw new Error(message);
        }
      },

      createComment: async (comment, errorId) => {
        const { updateCommentsLocally } = get();

        set(() => ({ error: undefined, isLoading: true }));

        try {
          const { data } = await axios.post<IComment>('/comments', comment);
          updateCommentsLocally({ event: 'add', itemOrId: data });
        } catch {
          const message = getErrorMessage('create', 'comment');

          set(() => ({ error: { id: errorId, message }, isLoading: false }));

          throw new Error(message);
        }
      },

      updateComment: async (comment) => {
        const { updateCommentsLocally } = get();

        updateCommentsLocally({
          event: 'update',
          itemOrId: comment,
          options: { isLoading: true },
        });

        try {
          const { data } = await axios.put<IComment>('/comments', comment);
          updateCommentsLocally({ event: 'update', itemOrId: data });
        } catch {
          const message = getErrorMessage('update', 'comment');

          updateCommentsLocally({
            options: { error: { id: comment.id, message } },
          });

          throw new Error(message);
        }
      },

      deleteComment: async (id) => {
        const { updateCommentsLocally } = get();

        set(() => ({ error: undefined, isLoading: true }));

        try {
          await axios.delete(`/comments/${id}`);
          updateCommentsLocally({ event: 'remove', itemOrId: id });
        } catch {
          const message = getErrorMessage('delete', 'comment');

          set(() => ({ isLoading: false, error: { message } }));

          throw new Error(message);
        }
      },

      updateCommentsLocally: ({
        event,
        itemOrId,
        options: {
          editingId = undefined,
          error = undefined,
          isLoading = false,
        } = {},
      }) => {
        let { comments } = get();

        if (event === 'add' || event === 'update') {
          const { collection } = updateResourceLocally({
            event,
            itemOrId,
            collection: comments,
          });

          comments = collection ?? comments;
        }
        if (event === 'remove') {
          const { collection } = updateResourceLocally({
            event,
            itemOrId,
            collection: comments,
          });

          comments = collection ?? comments;
        }

        set(() => ({
          isLoading,
          editingId,
          error,
          comments,
        }));
      },

      setScrollCommentsIntoView: (value) => {
        if (get().scrollCommentsIntoView !== value) {
          set(() => ({ scrollCommentsIntoView: value }));
        }
      },

      setEditingId: (id) => {
        if (get().editingId !== id) {
          set(() => ({ editingId: id }));
        }
      },
    }),

    { name: 'comments' },
  ),
  shallow,
);
