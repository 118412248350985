import React, { useMemo } from 'react';
import {
  useSortProjectsBy,
  setSortProjectsBy,
} from '../../hooks/filter-projects.hook';
import SortSelect, { SortSelectOptions } from './SortSelect';
import { OneOfProjectListElements } from '../../../../shared/models/project.interface';

const SortProjectsSelect = () => {
  const sortBy = useSortProjectsBy();
  const options = useMemo<SortSelectOptions<OneOfProjectListElements>>(
    () => ({
      name: 'Project name',
      owner: 'Owner',
      gfa: 'Gross Floor Area',
      updated_at: 'Updated at',
    }),
    [],
  );

  return (
    <SortSelect
      sortBy={sortBy}
      setSortBy={setSortProjectsBy}
      options={options}
    />
  );
};

export default SortProjectsSelect;
