import React, { useCallback, useEffect, useRef } from 'react';
import MainView from './views/MainView';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './components/Loading';
import Auth0TokenRefresher from './Auth0TokenRefresher';
import amplitudeLog from './amplitude';
import * as Sentry from '@sentry/react';
import { useConfig } from './providers/ConfigProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import {
  detectScreenResolution,
  detectViewport,
} from '../../shared/helpers/analytics_helpers';
import { useInitUIStore } from './store/ui';
import { useSocketListeners } from './hooks/sockets.hook';
import { useSelectedOrganization } from './store/organization';
import { getUserOrganizationNames } from '../../shared/helpers/user.helpers';
import { useUser } from './hooks/user.hook';
import NodonSnackbarProvider from './providers/NodonSnackbarProvider';

const App: React.FC = () => {
  const [config] = useConfig();
  const { isLoading, isAuthenticated } = useAuth0();
  useInitUIStore();

  const user = useUser();

  const selectedOrganization = useSelectedOrganization();
  const selectedOrganizationRef = useRef<string | undefined>(
    selectedOrganization,
  );
  const [addSocketListeners, clearSocketListeners] = useSocketListeners();
  const socketListenersRegistered = useRef<boolean>(false);
  const hubSpotChatbotInitiated = useRef<boolean>(false);

  // Only enable sentry on test and production
  if (config.sentryEnvironment && config.sentryEnvironment !== 'localhost') {
    Sentry.init({
      dsn: 'https://861cbd98d6ba439ba03140af69ca3a8a@o1180279.ingest.sentry.io/6299705',
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ['error'],
        }),
      ],
      tracesSampleRate: 1.0,
      environment: config.sentryEnvironment,
      release: config.version,
    });
  }

  const login = useCallback(async () => {
    if (user) {
      const userGroups: Array<string> = getUserOrganizationNames(user);
      const isLoggedIn = window.sessionStorage.getItem('isLoggedIn');

      if (!socketListenersRegistered.current) {
        await addSocketListeners(socketListenersRegistered);
        selectedOrganizationRef.current = selectedOrganization;
      }

      if (
        socketListenersRegistered.current &&
        selectedOrganizationRef.current !== selectedOrganization
      ) {
        clearSocketListeners();
        await addSocketListeners(socketListenersRegistered);
        selectedOrganizationRef.current = selectedOrganization;
        return;
      }

      if (isLoggedIn === 'true') return amplitudeLog('Reload');
      window.sessionStorage.setItem('isLoggedIn', 'true');

      if (!socketListenersRegistered.current) {
        amplitudeLog('Session Start', {
          'User Groups': userGroups,
          'Screen Resolution': detectScreenResolution(),
          Viewport: detectViewport(),
        });
      }
    }
  }, [
    user,
    selectedOrganization,
    selectedOrganizationRef,
    socketListenersRegistered,
    addSocketListeners,
    clearSocketListeners,
  ]);

  const initHubSpotChatbot = useCallback(() => {
    if (
      config.sentryEnvironment === 'production' &&
      !hubSpotChatbotInitiated.current
    ) {
      const hubspot = document.createElement('script');
      hubspot.src = '//js-na1.hs-scripts.com/9365817.js';
      hubspot.async = true;
      hubspot.defer = true;
      hubspot.id = 'hs-script-loader';
      hubspot.type = 'text/javascript';
      document.head.appendChild(hubspot);
      hubSpotChatbotInitiated.current = true;
    }
  }, [config, hubSpotChatbotInitiated]);

  useEffect(() => {
    if (isAuthenticated) {
      login().catch((error) => console.error(error));
      initHubSpotChatbot();
    }
  }, [isAuthenticated, login, initHubSpotChatbot]);

  if (isLoading || !isAuthenticated) {
    return <Loading />;
  }

  return (
    <Auth0TokenRefresher>
      <ConfirmProvider>
        <NodonSnackbarProvider>
          <MainView />
        </NodonSnackbarProvider>
      </ConfirmProvider>
    </Auth0TokenRefresher>
  );
};

export default withAuthenticationRequired(App, {
  onRedirecting: () => <Loading />,
});
