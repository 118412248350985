import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { OneOfSelectableProductListElements } from '../../../../shared/models/product.interface';
import ProductSettings from './ProductSettings';
import { getProductElements } from '../../helpers/product-list.helpers';
import { isProductListItem } from '../../../../shared/helpers/recursive_element_helpers';

interface ProductElementProps {
  element: OneOfSelectableProductListElements;
}

const Product: React.FC<ProductElementProps> = ({ element }) => {
  const productElements = useMemo(() => getProductElements(element), [element]);

  return (
    !!productElements.length && (
      <Box p={2}>
        <ProductSettings
          product_id={element.product_id ?? element.id}
          generic_product_id={element.generic_product_id}
          unit={element.unit}
          generatedElements={productElements}
          count={element.results?.[element.unit]}
          disableConversionFactorInputs={isProductListItem(element)}
          disableEPDMenu={!element.generated}
        />
      </Box>
    )
  );
};

export default Product;
