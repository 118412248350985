import { getElementPropertyResolvedCountByNameOrId } from '../../helpers/element_property_helpers';
import { IFactoryProductElement } from '../../models/element_factory_helpers.interface';
import {
  ElementPropertyInputType,
  IFactoryProperty,
} from '../../models/element_property.interface';
import { IProduct, ProductID } from '../../models/product.interface';
import { ElementKind } from '../../models/project.interface';
import { IElement } from '../../../shared/models/project.interface';
import {
  PropertiesOptions,
  IElementCategoryElementsFn,
  IElementCategoryElementPropertiesFn,
  ElementCategoryID,
} from '../../models/element_categories.interface';
import {
  reusedContentProductId,
  reusedContentProperty,
  ProductCategoryPropertyName,
  ProductTree,
  ProductTreeCountMap,
  ReversedProductToStringMap,
  ProductTreeUnitMap,
} from './processor.model';
import { asArray, isDefined, isOneOf } from '../../helpers/array_helpers';
import {
  isQuantityUnit,
  isSelectableQuantityUnit,
} from '../../helpers/unit_helpers';
import { QuantityUnit } from '../../models/unit.interface';
import { getElementCategory } from '../../helpers/element_category_helpers';
import { getElementCategoryId } from '../../helpers/element_helpers';
import genericProducts from '../../generic_products';
import { getKeys } from '../../helpers/object_helpers';

interface IProductTreeProcessorOptions {
  productTree: ProductTree;
  levelProperties: IFactoryProperty[];
  propertiesOptions?: PropertiesOptions[];
  productCountMap?: ProductTreeCountMap;
  productUnitMap?: ProductTreeUnitMap;
}

type CreateProductTreeProcessorFn = (options: IProductTreeProcessorOptions) => {
  getElementProperties: IElementCategoryElementPropertiesFn;
  getChildElements: IElementCategoryElementsFn;
};
type EnumProductType = string;

const REUSED_FORBIDDEN_CATEGORY_IDS = [
  ElementCategoryID.Energy,
  ElementCategoryID.Labour,
] as const;

export const createProductTreeProcessor: CreateProductTreeProcessorFn = ({
  levelProperties,
  productTree,
  propertiesOptions = [],
  productCountMap = {},
  productUnitMap = {},
}) => {
  const levelPropertyNames = levelProperties
    .map((p) => p.name)
    .filter(isDefined);
  const propertiesValues: EnumProductType[] = [];
  const levels: (ProductTree | undefined)[] = [];
  let productIds: ProductID[] | undefined;
  const currentProperties: IFactoryProperty[] = [];

  const getNextLevel = (
    level: ProductTree | undefined,
    propertyValue: EnumProductType,
    index: number,
  ): ProductTree | undefined => {
    if (typeof level === 'object') {
      const values = Object.keys(level);
      const firstValue = values[0];

      if (firstValue) {
        propertiesValues[index] = values.includes(propertyValue)
          ? propertyValue
          : firstValue;

        return level[propertiesValues[index]] as ProductTree;
      }
    }
    return undefined;
  };

  const pushProperty = (
    level: ProductTree | undefined,
    property: IFactoryProperty,
  ): void => {
    if (
      typeof level === 'object' &&
      property.type === ElementPropertyInputType.Select
    ) {
      property.options = Object.keys(level);
      property.count = Object.keys(level)[0];
    }
    currentProperties.push(property);
  };

  const getOption = (
    propertyValue: EnumProductType,
    propertiesOptions: PropertiesOptions[],
    type?: string,
  ) => {
    const propertyOption = propertiesOptions.find(
      (item): boolean | string | undefined => {
        return type
          ? item[propertyValue]?.propertyType === type
          : item[propertyValue]?.propertyName;
      },
    );

    return propertyOption ? propertyOption[propertyValue] : undefined;
  };

  const processLevelsRecursive = (
    i: number,
    element: IElement,
    levelProperties: IFactoryProperty[],
    levelPropertyNames: string[],
    propertiesOptions: PropertiesOptions[],
  ): void => {
    const level = levels[i];
    const propertyValue = propertiesValues[i];

    if (propertyValue && typeof level === 'object' && !Array.isArray(level)) {
      const nameOption = getOption(propertyValue, propertiesOptions);
      const switchOption = getOption(
        propertyValue,
        propertiesOptions,
        'switch',
      );
      const option = nameOption ?? switchOption;

      // level is a object that is either a select or switch property
      if (option) {
        if (!option.property) {
          throw new Error(`No property in option: ${option.propertyName}`);
        }
        pushProperty(level, option.property);
        propertiesValues[i + 1] = getElementPropertyResolvedCountByNameOrId(
          element,
          option.propertyName,
          [option.property],
        );
      } else {
        const nextLevelProperty = levelProperties[i + 1];
        const nextLevelPropertyName = levelPropertyNames[i + 1];

        if (nextLevelProperty) {
          pushProperty(level, nextLevelProperty);
        }

        if (nextLevelPropertyName) {
          propertiesValues[i + 1] = getElementPropertyResolvedCountByNameOrId(
            element,
            nextLevelPropertyName,
            levelProperties,
          );
        }
      }

      const nextPropertyValue = propertiesValues[i + 1];

      if (nextPropertyValue !== undefined) {
        levels[i + 1] = getNextLevel(level, nextPropertyValue, i + 1);
      }

      processLevelsRecursive(
        i + 1,
        element,
        levelProperties,
        levelPropertyNames,
        propertiesOptions,
      );
    } else if (typeof level === 'string' || Array.isArray(level)) {
      const prevPropertyValue = propertiesValues[i - 1];

      const levelUpswitchOption = prevPropertyValue
        ? getOption(prevPropertyValue, propertiesOptions, 'switch')
        : undefined;

      const expressionOption = propertyValue
        ? getOption(propertyValue, propertiesOptions, 'expression')
        : undefined;

      if (levelUpswitchOption) {
        propertiesValues[i] = getElementPropertyResolvedCountByNameOrId(
          element,
          levelUpswitchOption.propertyName,
          [levelUpswitchOption.property],
        );

        const productIDPair = Object.values(levels[i - 1] ?? {});

        productIds = [
          propertiesValues[i]
            ? (productIDPair[0] as ProductID)
            : (productIDPair[1] as ProductID),
        ];
      } else {
        productIds = asArray(level);
      }

      if (expressionOption) {
        pushProperty(levels[i], expressionOption.property);
      }
    }
  };

  const getElementProperties = (element: IElement): IFactoryProperty[] => {
    // first property default value (try to get value from element as default)

    if (levelPropertyNames[0]) {
      propertiesValues[0] = getElementPropertyResolvedCountByNameOrId(
        element,
        levelPropertyNames[0],
        levelProperties, // Use default from category
      );
    }
    if (propertiesValues[0]) {
      levels[0] = productTree[propertiesValues[0]] as ProductTree; // first level
    }

    currentProperties.length = 0; // clear array

    // exclude EC Labour
    if (supportsReusedContent(element)) {
      currentProperties.push(reusedContentProperty); // add reused content property by default
    }
    if (levelProperties[0]) {
      currentProperties.push(levelProperties[0]); // add default property as default
    }

    processLevelsRecursive(
      0,
      element,
      levelProperties,
      levelPropertyNames,
      propertiesOptions,
    );

    return currentProperties;
  };

  const getProductCount = (
    productId: ProductID,
    categoryId: ElementCategoryID | undefined,
  ): string => {
    const categoryDefault = getElementCategory(categoryId)?.defaultCount;
    const defaultCount =
      typeof categoryDefault === 'string' ? categoryDefault : 'mass';
    return (
      getStringFromReversedProductToStringMap(productCountMap, productId) ??
      defaultCount
    );
  };

  const getProductUnit = (productId: ProductID): QuantityUnit => {
    const product = genericProducts.find((p) => p.id === productId);
    let unit = getStringFromReversedProductToStringMap(
      productUnitMap,
      productId,
    );
    const factors: IProduct['conversion_factors'] =
      product?.conversion_factors ?? {};

    if (!unit) {
      // Use kg or volume as preferred default unit but make sure it exists in conversion_factors
      unit = ['kg', 'm³', 'MJ', ...getKeys(factors)].find(
        (key) => isSelectableQuantityUnit(key) && !!factors[key],
      );
    }

    return isQuantityUnit(unit) ? unit : 'kg';
  };

  const getChildElements: IElementCategoryElementsFn = (element: IElement) => {
    const productElements: IFactoryProductElement[] = [];

    getElementProperties(element);

    const reusedSupport = supportsReusedContent(element);
    const categoryId = getElementCategoryId(element);

    // TODO: Should probably not use global scoped variable "productId" changing in real time, could cause bugs.
    const reusedPercentage = reusedSupport
      ? +getElementPropertyResolvedCountByNameOrId(
          element,
          ProductCategoryPropertyName.ReusedContent,
          [reusedContentProperty],
        )
      : 0;

    if (productIds) {
      const products: IFactoryProductElement[] = productIds.map(
        (product_id) => ({
          kind: ElementKind.Product,
          product_id,
          count: reusedSupport
            ? `(${1 - reusedPercentage}) * (${getProductCount(product_id, categoryId)})`
            : getProductCount(product_id, categoryId),
          unit: getProductUnit(product_id),
          generated: true,
        }),
      );
      productElements.push(...products);
    }
    const reusedExpression = getProductCount(
      ProductCategoryPropertyName.ReusedContent,
      categoryId,
    );

    // Add reused content last to make allow us to always get density from first product
    if (reusedPercentage) {
      productElements.push({
        kind: ElementKind.Product,
        product_id: reusedContentProductId,
        count: `(${reusedPercentage}) * (${reusedExpression})`,
        unit: 'kg', // ONLY existing unit for reused content product
        generated: true,
      });
    }
    return productElements;
  };

  return {
    getElementProperties,
    getChildElements,
  };
};

const getStringFromReversedProductToStringMap = (
  map: ReversedProductToStringMap,
  productId: ProductID | ProductCategoryPropertyName.ReusedContent,
): string | undefined => {
  let defaultValue: string | undefined;

  const entry = Object.entries(map).find(([count, ids]) => {
    if (typeof ids === 'string') {
      if (ids === '*') {
        defaultValue = count;
      } else if (ids === productId) {
        return true;
      }
    } else if (ids.includes(productId)) {
      return count;
    }
  });

  return entry ? entry[0] : defaultValue;
};

const supportsReusedContent = (element: IElement): boolean =>
  !isOneOf(REUSED_FORBIDDEN_CATEGORY_IDS, getElementCategoryId(element));
