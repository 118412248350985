import React, { ReactElement, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';
import { Box, BoxProps } from '@mui/material';
import { LIST_INDENTATION_SIZE } from './list.constants';
import { toNumber } from '../../../../shared/helpers/math_helpers';

export interface IRowCellInput
  extends Omit<BoxProps, 'height' | 'children' | 'padding'> {
  children?: ReactNode;
  align?: 'left' | 'center' | 'right';
  indentation?: number;
  width?: number;
  responsiveWidth?: number;
  ellipsis?: boolean;
  hideOnPrint?: boolean;
}

export const RowCell: React.FC<IRowCellInput> = ({
  children,
  align,
  indentation,
  width,
  responsiveWidth,
  ellipsis = false,
  hideOnPrint = false,
  ...props
}: IRowCellInput): ReactElement<IRowCellInput> => {
  const { classes, cx } = useStyles();
  align = typeof indentation === 'number' ? 'left' : align;
  const justifyContent = alignToJustifyContent(align);
  const defaultGrow = typeof width === 'number' ? 0 : 1;
  const flexGrow: number = responsiveWidth ?? defaultGrow;
  const paddingLeft = toNumber(props.paddingLeft);
  const indentationWidth =
    LIST_INDENTATION_SIZE * (indentation ?? 0) + paddingLeft;

  const fixedWidth = flexGrow === 0;

  return (
    <Box
      {...props}
      className={cx(classes.cell)}
      justifyContent={justifyContent}
      flexGrow={flexGrow}
      flexShrink={fixedWidth ? 0 : 1}
      paddingLeft={indentationWidth + 'px'}
      flexBasis={(width ?? 0) + indentationWidth}
      displayPrint={hideOnPrint ? 'none' : undefined}
    >
      {fixedWidth && ellipsis ? (
        <Box className={classes.ellipsis}>{children}</Box>
      ) : (
        children
      )}
    </Box>
  );
};

const alignToJustifyContent = (align: IRowCellInput['align']) => {
  if (align === 'right') {
    return 'flex-end';
  }
  if (align === 'center') {
    return 'center';
  }
  return 'start';
};

const useStyles = makeStyles()(({ spacing }) => ({
  cell: {
    minWidth: 0,
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    gap: spacing(2),
    overflow: 'visible',
  },
  ellipsis: {
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexShrink: 0,
  },
  hideOnPrint: {
    displayPrint: 'none',
  },
}));
