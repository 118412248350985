import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import amplitudeLog from '../../../amplitude';
import {
  getSelectableUnitsInConversionFactors,
  selectableUnitHarmonizer,
} from '../../../../../shared/helpers/unit_helpers';
import ExpressionInput from '../../ExpressionInput';
import {
  ExpressionValue,
  IProductElement,
} from '../../../../../shared/models/project.interface';
import { useUpdateElements } from '../../../store/project/project.hook';
import { useIsSelected } from '../../../store/ui/ui.hook';
import { useElementExpressionVariablesById } from '../../../hooks/useElementExpressionVariables';
import { useIsReadonly } from '../../../hooks/user.hook';
import { useToggleElementExpanded } from '../../../hooks/expand-elements.hook';
import { Row } from '../Row';
import { RowCell } from '../RowCell';
import {
  ROOT_CELL_WIDTH,
  ROOT_CELL_WIDTH_RESPONSIVE,
  LIST_ITEM_HEIGHT,
  LIST_SPACING,
  CONTENT_CELL_WIDTH,
  CONTENT_CELL_WIDTH_RESPONSIVE,
  ELEMENT_LIST_ITEM_HEIGHT,
} from '../list.constants';
import { useBooleanState } from '../../../hooks/hooks';
import {
  useIsMenuOpen,
  useTriggerContextMenu,
} from '../../../hooks/menu.hooks';
import { LIST_BORDER, useListRowStyles } from '../list.style';
import CreateSiblingButton, {
  useShouldShowCreateSiblingButton,
} from '../../CreateSiblingButton';
import { useProductSwitch } from '../../../hooks/product-switch.hook';
import {
  useGenericProduct,
  useSelectedVersionProduct,
} from '../../../hooks/useProducts';
import { applyCalculatedConversionFactors } from '../../../../../shared/helpers/conversion_helpers';
import { SelectableQuantityUnit } from '../../../../../shared/models/unit.interface';
import ProductSelector from '../../../projects/EditProject/ProductSelector';
import { IExpressionInputPanelOutput } from '../../ExpressionInputPanel';
import ProductSelectorButton from '../../ProductSelectorButton';
import ElementBarCharts from '../../charts/ElementBarCharts';
import { useIsDeactivated, useParentElement } from '../../../hooks/useElement';
import { isElement } from '../../../../../shared/helpers/recursive_element_helpers';
import ExpandButton from '../Buttons/ExpandButton';
import EPDMenu from '../../EPDMenu/EPDMenu';
import { isGeneratedProductElement } from '../../../../../shared/helpers/element_helpers';
import ProductElementKebabMenu from '../../menus/KebabMenu/KebabMenus/ProductElementKebabMenu';

interface IProductElementListItemInput {
  element: IProductElement;
  indentation?: number;
}

const ProductElementListItem: React.FC<IProductElementListItemInput> = ({
  element,
  indentation = 0,
}) => {
  const { classes: listClasses } = useListRowStyles();

  const {
    isProductSelectorOpen,
    openProductSelector,
    closeProductSelector,
    switchProducts,
  } = useProductSwitch(element);

  const { id, product_id, generated: isGenerated } = element;

  const updateElements = useUpdateElements();
  const triggerContextMenu = useTriggerContextMenu(id);
  const toggleElementExpanded = useToggleElementExpanded(element);

  const readonly = useIsReadonly();
  const isKebabMenuOpen = useIsMenuOpen(id);
  const selected = useIsSelected(element);
  const deactivated = useIsDeactivated(element);
  const showCreateSiblingButton = useShouldShowCreateSiblingButton(element);

  const variables = useElementExpressionVariablesById(id);
  const parent = useParentElement(element);
  const product = useSelectedVersionProduct(product_id);
  const genericProduct = useGenericProduct(element);

  const [hover, startHover, endHover] = useBooleanState(false);
  const [unit, setUnit] = useState(element.unit);
  const [count, setCount] = useState<ExpressionValue>(element.count);

  const conversionFactors = useMemo(() => {
    const factors = product?.conversion_factors;
    return factors ? applyCalculatedConversionFactors(factors) : {};
  }, [product?.conversion_factors]);

  const selectableUnits: SelectableQuantityUnit[] = useMemo(
    () => getSelectableUnitsInConversionFactors(conversionFactors),
    [conversionFactors],
  );

  const handleOnSave = useCallback(
    async (updatedProductElement: IProductElement) =>
      updateElements(updatedProductElement),
    [updateElements],
  );

  const handleProductClick = useCallback(() => {
    if (isGenerated) {
      return;
    }
    if (selected) {
      openProductSelector();
    } else {
      closeProductSelector();
    }
    amplitudeLog('Product Switch', {
      ProductID: product?.id,
    });
  }, [
    isGenerated,
    selected,
    product?.id,
    openProductSelector,
    closeProductSelector,
  ]);

  const handleExpressionInput = useCallback(
    ({ expressionValue, unit }: IExpressionInputPanelOutput) => {
      if (!expressionValue) {
        throw new Error('Provided value must be an ExpressionValue');
      }

      setCount(expressionValue);
      amplitudeLog('Product Value Set', {
        ProductID: product?.id,
      });

      handleOnSave({
        ...element,
        count: expressionValue,
        unit: unit ?? element.unit,
      });
    },
    [product?.id, element, setCount, handleOnSave],
  );

  useEffect(() => {
    setUnit(element.unit);
  }, [element.unit]);

  useEffect(() => {
    setCount(element.count);
  }, [element.count]);

  return (
    <>
      <Row
        classes={listClasses}
        deactivated={deactivated}
        height={ELEMENT_LIST_ITEM_HEIGHT}
        hover={hover}
        onContextMenu={triggerContextMenu}
        onDoubleClick={toggleElementExpanded}
        onMouseLeave={endHover}
        onMouseOver={startHover}
        padding={true}
        selected={selected}
        spacing={LIST_SPACING}
        // borderTop={LIST_BORDER}
      >
        {/* CONTENT. Group content in a shared cell to make sure we can align bar charts as one */}
        <RowCell
          width={ROOT_CELL_WIDTH.CONTENT}
          borderRight={LIST_BORDER}
          paddingRight={LIST_SPACING}
        >
          <Row height={LIST_ITEM_HEIGHT} spacing={LIST_SPACING}>
            <RowCell
              indentation={indentation} // Put indentation on the first item in row
              width={ROOT_CELL_WIDTH.ICON}
            ></RowCell>
            <RowCell // Put indentation on the first item in row
              width={ROOT_CELL_WIDTH.ICON}
            >
              <ExpandButton element={element} disabled={true} />
            </RowCell>

            {/* Name */}
            <RowCell
              width={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.NAME}
            >
              <ProductSelectorButton
                onClick={handleProductClick}
                selected={selected ?? false}
                error={!product.id}
                productName={genericProduct?.name ?? product.name}
                disabled={readonly || isGenerated}
              />
            </RowCell>
          </Row>

          {/* EPD selector */}
          {
            <RowCell
              align="right"
              width={CONTENT_CELL_WIDTH.ACTIONS}
              responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.ACTIONS}
            >
              {isElement(parent) &&
                isGeneratedProductElement(parent) &&
                (hover || selected || genericProduct) && (
                  <EPDMenu
                    variant="button"
                    product_id={product_id}
                    generic_product_id={genericProduct?.id}
                    productCategoryElement={parent}
                  />
                )}
            </RowCell>
          }
        </RowCell>

        {/* Bar chart */}
        <RowCell
          width={ROOT_CELL_WIDTH.BAR}
          responsiveWidth={ROOT_CELL_WIDTH_RESPONSIVE.BAR}
        >
          <ElementBarCharts element={element} />
        </RowCell>

        {/* Expression input */}
        <RowCell
          align="right"
          width={CONTENT_CELL_WIDTH.EXPRESSION}
          // responsiveWidth={CONTENT_CELL_WIDTH_RESPONSIVE.EXPRESSION}
        >
          <ExpressionInput
            id={id}
            expressionValue={count}
            unit={selectableUnitHarmonizer(unit)}
            selectableUnits={selectableUnits}
            variables={variables}
            disabled={readonly || isGenerated}
            onSave={handleExpressionInput}
          />
        </RowCell>

        {/* Align with IElement */}
        <RowCell width={CONTENT_CELL_WIDTH.ICON}></RowCell>

        {/* Kebab menu */}
        <RowCell width={CONTENT_CELL_WIDTH.ICON} hideOnPrint align="center">
          {(hover || isKebabMenuOpen) && (
            <ProductElementKebabMenu element={element} onClose={endHover} />
          )}
        </RowCell>
      </Row>
      {showCreateSiblingButton && (
        <CreateSiblingButton element={element} indentation={indentation} />
      )}
      {isProductSelectorOpen && !isGenerated && (
        <ProductSelector
          open={isProductSelectorOpen}
          onClose={closeProductSelector}
          onSave={switchProducts}
          productId={product.id}
        />
      )}
    </>
  );
};

export default memo(ProductElementListItem);
