import { useMemoDeepEqual } from '../../hooks/hooks';
import { useFolderStateStore, IFolderState } from './folder.store';
import { pick } from '../../../../shared/helpers/object_helpers';
import { IProjectFolder } from '../../../../shared/models/folder.interface';
import {
  useProjects,
  useUpdateProjectAndFolderLocations,
} from '../project/project.hook';
import { useCallback, useMemo } from 'react';
import { getProjectFolderTree } from '../../../../shared/helpers/project-folder.helpers';
import { useIsConfirmed } from '../../hooks/confirm.hook';
import {
  IProjectInfo,
  OneOfProjectListElements,
} from '../../../../shared/models/project.interface';
import amplitudeLog from '../../amplitude';

/**
 * Create a new object including a subset of propetries from an object
 * @param obj
 * @param keys
 * @returns
 */
export function useFolderState<K extends keyof IFolderState>(
  ...keys: K[]
): Pick<IFolderState, K> {
  // Create a memoed selector function to pick properties
  const selector = useMemoDeepEqual(
    () => (o: IFolderState) => (keys.length ? pick(o, ...keys) : o),
    keys,
  );
  return useFolderStateStore(selector);
}

export const useDeleteFolder = () => {
  const deleteFolder = useFolderStateStore((state) => state.deleteFolder);
  const updateProjectAndFolderLocations = useUpdateProjectAndFolderLocations();
  const confirm = useIsConfirmed();
  const folders = useFolders();
  const projects = useProjects();

  // Old code, needs to be refactored to not run updateProjectAndFolderLocations twice
  const updateLocationsAndDelete = useCallback(
    async (id: IProjectFolder['id']) => {
      const foldersAndProjectInfos = [...folders, ...projects];
      const itemsInRoot = foldersAndProjectInfos.filter(
        ({ parent_id }) => parent_id === null,
      );
      const itemsInParent: OneOfProjectListElements[] = foldersAndProjectInfos
        .filter(({ parent_id }) => parent_id === id)
        .sort((a, b) => a.location - b.location)
        .map((item, index) => ({
          ...item,
          parent_id: null,
          location: index + (itemsInRoot.length ?? 1) - 1,
        }));

      await updateProjectAndFolderLocations(itemsInParent);
      await deleteFolder(id);
      const projectInfosInRoot = projects.filter(
        (projectInfo) => projectInfo.parent_id === null,
      );

      const foldersInRoot = folders.filter(
        (folder) => folder.id !== id && folder.parent_id === null,
      );

      const itemsWithUpdatedLocations = [
        ...projectInfosInRoot,
        ...foldersInRoot,
        ...itemsInParent,
      ]
        .sort((a, b) => a.location - b.location)
        .map((item, index) => ({ ...item, location: index }));

      await updateProjectAndFolderLocations(itemsWithUpdatedLocations);

      amplitudeLog('Project Folder Delete', {
        ProjectID: id,
      });
    },
    [folders, projects, updateProjectAndFolderLocations, deleteFolder],
  );
  return useCallback(
    async (id: IProjectFolder['id']) => {
      const folder = getFolders().find((f) => f.id === id);
      if (!folder) {
        throw new Error(`Folder not found`);
      }
      const confirmed = await confirm({
        title: `Delete ${folder.name}?`,
        confirmationText: 'Delete',
        content: `This will move all the contents of the folder out to the root level and delete the folder. This action cannot be undone.`,
      });
      if (confirmed) {
        return await updateLocationsAndDelete(id);
      }
    },
    [confirm, updateLocationsAndDelete],
  );
};

const foldersSelector = (state: IFolderState): IProjectFolder[] =>
  state.folders;

/**
 * Get folders without causing rerenders
 * @returns
 */
export const getFolders = (): IProjectFolder[] =>
  foldersSelector(useFolderStateStore.getState());

export const useFolders = () =>
  useFolderStateStore((state) => foldersSelector(state));

export const useProjectInfosAndProjectFolders = () => {
  const folders = useFolders();
  const projects = useProjects();
  return useMemo(() => [...folders, ...projects], [folders, projects]);
};

export const useProjectFolderTree = (ownerFilter?: string) => {
  const folders = useFolders();
  const projects = useProjects();
  return useMemo(() => {
    const filteredProjects = ownerFilter
      ? projects.filter(({ owner }) => owner === ownerFilter)
      : projects;

    return getProjectFolderTree([...folders, ...filteredProjects]);
  }, [folders, ownerFilter, projects]);
};

const useProjectInfoIsInParent = (): ((
  parentId: string,
  projectInfo: IProjectInfo,
) => boolean) => {
  const { folders } = useFolderState('folders');

  return useCallback(
    (parentId, projectInfo) => {
      let inParent = false;
      let currentFolder = folders.find(
        ({ id }) => id === projectInfo.parent_id,
      );

      while (!inParent && currentFolder) {
        if (currentFolder?.id === parentId) {
          inParent = true;
        } else {
          currentFolder = folders.find(
            ({ id }) => id === currentFolder?.parent_id,
          );
        }
      }
      return inParent;
    },
    [folders],
  );
};

export const useFolderGFA = (): ((folder: IProjectFolder) => number) => {
  const projectInfos = useProjects();
  const isInParent = useProjectInfoIsInParent();

  return useCallback(
    (folder) =>
      projectInfos.reduce((acc, projectInfo) => {
        return isInParent(folder.id, projectInfo)
          ? acc + (projectInfo.gfa ?? 0)
          : acc;
      }, 0),
    [isInParent, projectInfos],
  );
};
