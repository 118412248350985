import { IComment } from '../../../shared/models/comment.interface';
import { IProduct } from '../../../shared/models/product.interface';
import { Recipe } from '../../../shared/models/recipe.interface';
import { IProjectInfo } from '../../../shared/models/project.interface';
import {
  getTimestamp,
  getTimestamps,
} from '../../../shared/helpers/date.helpers';
import { toLookup } from '../../../shared/helpers/utils.helpers';
import { omit } from '../../../shared/helpers/object_helpers';

/**
 * reload page if client version doesn't match server version
 * (ie after releasing a new version of the app)
 * */
export const reloadApp = (responseStatus: number): void => {
  if (responseStatus === 409) {
    location.reload();
  }
};

export const getErrorMessage = (
  action: 'get' | 'create' | 'update' | 'delete',
  resource: string,
): string => `Could not ${action} ${resource}`;

export type UpdateClientData<T> =
  | { event: 'add' | 'update'; itemOrId: T }
  | { event: 'remove'; itemOrId: string }
  | { event?: null; itemOrId?: null };

type CollectionTypes = IComment | IProduct | Recipe | IProjectInfo;

type UpdateClientCollectionData<T extends CollectionTypes> = {
  collection?: T[];
  lookup?: Record<string, T>;
};

type UpdateClientDataFn = <T extends CollectionTypes>(
  args: UpdateClientData<T> & UpdateClientCollectionData<T>,
) => UpdateClientCollectionData<T>;

const toCollectionData = <T extends CollectionTypes>(
  collection: T[],
): UpdateClientCollectionData<T> => {
  return {
    collection,
    lookup: toLookup(collection),
  };
};

const defaultCollectionData = { collection: undefined, lookup: undefined };

const getCollectionData: UpdateClientDataFn = ({
  event,
  itemOrId,
  collection,
  lookup,
}) => {
  if (event === 'add' || event === 'update') {
    if (collection) {
      return toCollectionData([
        ...collection.filter((item) => item.id !== itemOrId.id),
        itemOrId,
      ]);
    }
    if (lookup) {
      return { lookup: { ...lookup, [itemOrId.id]: itemOrId } };
    }
  }
  if (event === 'remove') {
    if (collection) {
      return toCollectionData(
        collection.filter((item) => item.id !== itemOrId),
      );
    }
    if (lookup) {
      return { lookup: omit(lookup, itemOrId) };
    }
  }
  return defaultCollectionData;
};

export const updateResourceLocally: UpdateClientDataFn = ({
  event,
  itemOrId,
  collection,
  lookup,
}) => {
  switch (event) {
    case 'add': {
      const timestamped = { ...itemOrId, ...getTimestamps() };

      return getCollectionData({
        event,
        itemOrId: timestamped,
        collection,
        lookup,
      });
    }
    case 'update': {
      const timestamped = { ...itemOrId, updated_at: getTimestamp() };

      return getCollectionData({
        event,
        itemOrId: timestamped,
        collection,
        lookup,
      });
    }
    case 'remove': {
      return getCollectionData({ event, itemOrId, collection, lookup });
    }
    default:
      return { collection, lookup };
  }
};
