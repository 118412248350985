import React, { FC, useEffect, useMemo, useState } from 'react';
import { Drawer, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Insights from './Insights';
import Element from './Element';
import { isElement } from '../../../../shared/helpers/recursive_element_helpers';
import ProjectDetailsEditor from '../ProjectDetailsEditor';
import { useProjectState } from '../../store/project';
import { useUIState } from '../../store/ui';
import { SidePanelStyles } from '../../style/constants';
import { useDrawerPaperProps } from '../../hooks/styles.hook';
import Product from './Product';
import ElementClose from './Element/ElementClose';
import { useSelectedProductListElement } from '../../hooks/product-list.hook';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    width: SidePanelStyles.WIDTH,
    flexShrink: 0,
  },

  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}));

interface SidePanelProps {
  panelPosition: 'left' | 'right';
}

const SidePanel: FC<SidePanelProps> = ({ panelPosition }) => {
  const {
    selectedPage,
    selectedVersion,
    selectedElement,
    showProjectDetailsEditor,
  } = useUIState(
    'selectedPage',
    'selectedVersion',
    'selectedElement',
    'showProjectDetailsEditor',
  );
  const { classes } = useStyles();
  const { project } = useProjectState('project', 'isLoading');
  const selectedProductListElement = useSelectedProductListElement();

  const [isNewProject, setIsNewProject] = useState(false);

  const showCloseButton =
    showProjectDetailsEditor ||
    selectedProductListElement ||
    isElement(selectedElement);

  useEffect(() => {
    setIsNewProject(true);
  }, [project?.id]);

  const content = useMemo(() => {
    if (!selectedVersion) {
      return undefined;
    }
    if (showProjectDetailsEditor) {
      return (
        <ProjectDetailsEditor
          project={project}
          isNewProject={isNewProject}
          setIsNewProject={setIsNewProject}
        />
      );
    }
    if (selectedPage === 'elements' && isElement(selectedElement)) {
      return <Element selectedElement={selectedElement} />;
    }
    if (selectedPage === 'products' && selectedProductListElement) {
      return <Product element={selectedProductListElement} />;
    }
    return <Insights project={project} version={selectedVersion} />;
  }, [
    selectedVersion,
    showProjectDetailsEditor,
    selectedPage,
    selectedElement,
    selectedProductListElement,
    project,
    isNewProject,
  ]);

  return (
    <Drawer
      variant="permanent"
      anchor={panelPosition}
      className={classes.drawer}
      sx={DRAWER_SX}
      PaperProps={useDrawerPaperProps(DRAWER_PAPER_SX)}
    >
      {showCloseButton && <ElementClose />}
      {content}
    </Drawer>
  );
};

const DRAWER_SX = { displayPrint: 'none' } as const;

const DRAWER_PAPER_SX = {
  width: SidePanelStyles.WIDTH,
  paddingBottom: '48px',
  overflowX: 'hidden',
  zIndex: 900,
} as const;

export default SidePanel;
