import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  MouseEvent,
  FC,
} from 'react';
import {
  OutlinedInputProps,
  OutlinedTextFieldProps,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Variant } from '@mui/material/styles/createTypography';
import { formatToExpressionVariable } from '../../../shared/helpers/expression_variables_helpers';
import {
  isValidVariableName,
  isAvailableName,
} from '../../../shared/helpers/mathjs';
import { useEllipsesStyles } from '../style/ellipsis';
import { useIsReadonly } from '../hooks/user.hook';
import NodonTextField from './NodonTextField';
import { NodonTheme } from '../style';

const useStyles = makeStyles<void, 'noborder'>()((
  { palette: { text, neutral } },
  _params,
  classes,
) => {
  const strikeThroughLine = {
    content: '""',
    position: 'absolute' as const,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    height: 2,
    right: 0,
    left: 0,
    background: text.secondary,
  };

  return {
    root: {
      '&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-readOnly) .MuiOutlinedInput-notchedOutline':
        {
          borderColor: neutral.lighter,
        },

      // Disabled is like readonly, but without graying out the text
      '&.Mui-disabled': {
        WebkitTextFillColor: neutral.light,
      },
      // Prevent pointer events and user selection when disabled or readonly
      '&.Mui-disabled, &.Mui-readOnly': {
        borderColor: 'transparent !important',
        pointerEvents: 'none',
        userSelect: 'none',

        [`.${classes.noborder}`]: {
          borderColor: 'transparent !important',
        },
      },
    },
    inactive: {
      WebkitTextFillColor: neutral.light,
    },
    noborder: {
      borderColor: 'transparent',
    },
    input: {
      fontSize: NodonTheme.typography.fontSize,
      padding: '8.5px 8px',
    },
    label: {
      fontSize: NodonTheme.typography.fontSize,
      left: -6,
      '&.Mui-focused': {
        left: 0,
      },
    },
    strikeThrough: {
      '&::placeholder': {
        textDecoration: 'line-through !important',
      },
      '&::after': {
        ...strikeThroughLine,
        left: 3,
      },
    },
    pointer: {
      cursor: 'pointer',
    },
    tabStyles: {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontSize: '0.875rem',
    },
  };
});

interface InlineTextFieldProps {
  value: string;
  /** controls if border is visible and editing is possible. focuses the input on changing from anything -> true */
  defaultValue?: string;
  editing?: boolean;
  tabInput?: boolean;
  displayBorder?: boolean;
  textFieldProps?: Partial<OutlinedTextFieldProps>;
  variant?: Variant;
  error?: boolean;

  /**
   * Like disabled, but without graying out the text.
   */
  readonly?: boolean;

  /**
   * Grayed out without input possibility to edit.
   */
  disabled?: boolean;

  /**
   * Grayed out without affecting input possibility to edit.
   */
  inactive?: boolean;

  /**
   * Make input width 100%
   */
  fullWidth?: boolean;
  allowClickEventWhenDisabled?: boolean;

  /**
   * Focus the input when input is added to the DOM.
   */
  autoFocus?: boolean;

  /**
   * Make input width adjust to text width.
   */
  autoWidth?: boolean;

  /**
   * Make text bold.
   */
  bold?: boolean;

  /**
   * Apply strikethrough to the text.
   */
  strikeThrough?: boolean;

  onCancel?: () => void;
  onClick?: (e: MouseEvent) => void;
  onSave?: (value: string) => void;
  setNameIsInvalid?: (valid: boolean) => void;
}

const noBubble = (e: FocusEvent | MouseEvent | KeyboardEvent): void => {
  e.preventDefault();
  e.stopPropagation();
};

const getWidthOfText = (
  text: string,
  styles = {
    fontFamily: 'sans-serif',
    fontSize: 16,
    fontWeight: 400,
  },
) => {
  const element = document.createElement('div');
  const styleKeys = Object.keys(styles);
  for (let i = 0, n = styleKeys.length; i < n; ++i) {
    element.style[styleKeys[i] as unknown as number] =
      styles[styleKeys[i] as 'fontFamily'];
  }
  element.style.display = 'inline-block';
  element.innerHTML = text;
  document.body.appendChild(element);
  const width = element.offsetWidth;
  document.body.removeChild(element);
  return width + 4;
};

const InlineTextField: FC<InlineTextFieldProps> = ({
  value,
  defaultValue,
  editing,
  autoFocus = true,
  tabInput,
  displayBorder,
  textFieldProps,
  variant,
  error,
  disabled,
  inactive,
  readonly: readonlyParam,
  strikeThrough,
  allowClickEventWhenDisabled,
  fullWidth = true,
  autoWidth,
  bold,
  onCancel,
  onClick,
  onSave,
  setNameIsInvalid,
}) => {
  // If hook is true, use hook value. If param is true, use param value. Otherwise, false.
  const readonly = useIsReadonly() || readonlyParam || false;
  const { classes, cx } = useStyles();
  const { typography } = useTheme();
  const ellipsis = useEllipsesStyles();
  const [inputTextWidth, setInputTextWidth] = useState<number>();
  const [localValue, setLocalValue] = useState(value);

  const inputRef = useRef<HTMLInputElement | undefined>(undefined);

  const trimmedValue = localValue.trim();

  const variantStyle: Partial<typeof Typography> = useMemo(() => {
    if (!variant) {
      return {};
    }

    const { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing } =
      typography[variant];
    return { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing };
  }, [typography, variant]);

  useEffect(() => {
    if (!!editing && inputRef.current && !disabled && !readonly) {
      // Make sure new component is rendered before focusing
      setTimeout(() => {
        inputRef.current?.focus();
      });
    }
  }, [editing, disabled, autoFocus, readonly]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    if (strikeThrough || !editing) {
      setInputTextWidth(getWidthOfText(value));
    }
    // Do not add anything to this dependency array, so it only runs once!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setNameIsInvalid) {
      const expressionName = formatToExpressionVariable(localValue);
      const nameIsValid =
        isValidVariableName(expressionName) && isAvailableName(expressionName);

      setNameIsInvalid(!nameIsValid);
    }
  }, [setNameIsInvalid, localValue]);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLInputElement>) => {
      if (editing) {
        noBubble(e);
      }
      if (allowClickEventWhenDisabled || !disabled) {
        onClick?.(e);
      }
    },
    [allowClickEventWhenDisabled, disabled, editing, onClick],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (editing && (e.key === 'Escape' || e.key === 'Enter')) {
        return noBubble(e);
      }
    },
    [editing],
  );

  const handleKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (editing) {
        if (e.key === 'Enter' && trimmedValue !== value) {
          noBubble(e);
          if (onSave) {
            onSave(trimmedValue);
          }
        } else if (e.key === 'Escape') {
          noBubble(e);
          setLocalValue(value);
          if (onCancel) {
            onCancel();
          }
        }
      }
    },
    [editing, onCancel, onSave, trimmedValue, value],
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (editing && !disabled) {
        noBubble(e);
        if (trimmedValue !== value) {
          if (onSave) {
            onSave(trimmedValue);

            if (localValue) {
              setInputTextWidth(getWidthOfText(localValue));
            }
          }
        } else if (onCancel) {
          onCancel();
        }
      }
    },
    [disabled, editing, onCancel, onSave, trimmedValue, value, localValue],
  );

  const inputProps: Partial<OutlinedInputProps> = useMemo(
    () => ({
      ...textFieldProps?.InputProps,
      readOnly: (disabled || readonly) && !editing,
      disabled: disabled,
      classes: {
        root: cx(classes.root, inactive && classes.inactive),
        notchedOutline:
          !displayBorder && !editing ? classes.noborder : undefined,
        input: cx(
          !displayBorder && !editing ? classes.pointer : undefined,
          tabInput && classes.tabStyles,
          !editing && ellipsis.classes.ellipsis,
          strikeThrough && !editing && classes.strikeThrough,
          classes.input,
        ),
      },
      style: variantStyle,
    }),
    [
      textFieldProps?.InputProps,
      disabled,
      readonly,
      editing,
      cx,
      classes.root,
      classes.inactive,
      classes.noborder,
      classes.pointer,
      classes.tabStyles,
      classes.strikeThrough,
      classes.input,
      inactive,
      displayBorder,
      tabInput,
      ellipsis.classes.ellipsis,
      strikeThrough,
      variantStyle,
    ],
  );

  const sx = useMemo((): SxProps<Theme> | undefined => {
    return {
      ...textFieldProps?.sx,
      width: !fullWidth && inputTextWidth ? inputTextWidth + 32 : '100%',
      '&::after': {
        width: strikeThrough && localValue !== '' ? inputTextWidth : 0,
      },
    };
  }, [
    textFieldProps?.sx,
    fullWidth,
    inputTextWidth,
    strikeThrough,
    localValue,
  ]);

  return (
    <NodonTextField
      {...textFieldProps}
      inputRef={inputRef}
      variant="outlined"
      size="small"
      InputProps={inputProps}
      className={cx(
        strikeThrough && localValue !== '' && !editing && classes.strikeThrough,
      )}
      sx={sx}
      bold={bold}
      autoWidth={autoWidth}
      disabled={!!disabled}
      placeholder={defaultValue}
      value={localValue}
      error={error}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      onBlur={handleBlur}
      onClick={handleClick}
    />
  );
};

export default InlineTextField;
