import {
  ElementPropertyInputType,
  IFactoryProperty,
} from '../../models/element_property.interface';
import { ProductID } from '../../models/product.interface';

// General properties for all product categories

export enum ProductCategoryPropertyName {
  ReusedContent = 'reused_content',
}

export const reusedContentProductId = 'boverket_sv-SE_6000000174';

export const reusedContentProperty: Readonly<IFactoryProperty> = {
  name: ProductCategoryPropertyName.ReusedContent,
  type: ElementPropertyInputType.Expression,
  unit: '%',
  min: 0,
  max: 100,
  fallbackCount: 0,
};

/**
 * Nested tree structure of product IDs to help to set up an options/properties tree
 */
export interface ProductTree {
  [key: string]: ProductTree | ProductID | ProductID[];

  // TODO Should we used this to be able to backtrack productID
  // name: string | undefined;
}

export type ReversedProductToStringMap = {
  [expression: string]:
    | ProductID[]
    | ProductID
    | ProductCategoryPropertyName.ReusedContent;
};

export type ProductTreeCountMap = ReversedProductToStringMap;
export type ProductTreeUnitMap = ReversedProductToStringMap;
