import { Box } from '@mui/material';
import React from 'react';
import GroupByCategorySwitch from '../components/filtering/GroupByCategorySwitch';
import SearchElements from '../components/filtering/SearchElements';
import PageNavigation from '../components/PageNavigation';
import SortElementsSelect from '../components/sorting/SortElementsSelect';

const ListHeader = () => {
  return (
    <Box display="flex" justifyContent="space-between" pr={2} pl={2}>
      <PageNavigation />

      <Box display="flex" alignItems="center" gap={2}>
        <GroupByCategorySwitch />
        <SortElementsSelect />
        <SearchElements variant="elements" />
      </Box>
    </Box>
  );
};

export default ListHeader;
