import {
  ElementKind,
  IProductElement,
  IElement,
  IBuildingVersion,
  ElementKindMap,
  OneOfChildElements,
  OneOfParentElements,
  OneOfElementListChildren,
} from '../models/project.interface';
import {
  FactoryCountExpression,
  IFactoryProductElement,
} from '../models/element_factory_helpers.interface';
import {
  ArrayOrSingle,
  Replace,
  RequireProperties,
  SemiPartial,
} from '../models/type_helpers.interface';
import { hasDefinedProperties, omitUndefined } from './object_helpers';
import {
  createCountAndUnit,
  createExpression,
} from './expression_factory_helpers';
import { IFactoryProperty } from '../models/element_property.interface';
import { createElementProperties } from './element_property_factory_helpers';
import { createElementQuantityRecord } from './element_quantity_helpers';
import { FactoryQuantityRecord } from '../models/element_quantities.interface';
import { validateElement } from '../validation/project.validation';
import { mainCategoryIds } from '../models/element_categories.interface';
import { getElementCategoryById } from '../templates/categories';
import {
  flattenElements,
  getChildElements,
  getElementKind,
  isElement,
} from './recursive_element_helpers';
import { asArray } from './array_helpers';
import {
  generateNewElementVersionIds,
  getElementVersionId,
  isActiveElementVersion,
  isElementVersionElement,
} from './element-version.helpers';
import { createProposal } from './proposal.helpers';
import {
  DEFAULT_PROPOSAL_NAME,
  IProposal,
} from '../models/proposals.interface';
import { findFreeName } from './string_helpers';
import { sortInsert } from './sort_helpers';
import {
  createId,
  getChildRegenerateIdsOptions,
  RegenerateIds,
  shouldRegenerateIds,
} from './id.helpers';

export type CreatedElement<T extends keyof ElementKindMap> = ElementKindMap[T];

/**
 * Create an array of elements from a partial array of elements
 * @param factoryElements
 * @param regenerateId If true it will regerate id for all elements even if they already exist
 * @returns
 */
export const createElements = <T extends OneOfFactoryElements>(
  factoryElements: T[] = [],
  regenerateIds?: RegenerateIds,
): CreatedElement<T['kind']>[] =>
  generateNewElementVersionIds(
    factoryElements.map((e) => createElementFromPartial(e, regenerateIds)),
    regenerateIds,
  );

/**
 * Make sure all child element is created with correct default values
 * @param defaults Partial with overrides of the default values
 * @param regenerateId If true it will regerate id for all elements even if id already exist
 * @returns
 */
const createChildElements = (
  defaults: Partial<IFactoryVersion | IFactoryElement>,
  regenerateIds?: RegenerateIds,
): OneOfChildElements[] =>
  createElements(
    defaults.elements,
    getChildRegenerateIdsOptions(regenerateIds),
  );

/**
 * Create a product element
 * @param defaults Partial with overrides of the default values
 * @param regenerateId how ids should be generated. Will regenerate by default.
 * @returns
 */
const createProductElement = (
  defaults: Partial<IFactoryProductElement>,
  regenerateIds?: RegenerateIds,
): IProductElement => {
  if (!defaults.product_id) {
    throw new Error('Product id is required');
  }

  return validateElement({
    kind: ElementKind.Product,
    product_id: '',
    unit: 'kg',
    ...omitUndefined(defaults),
    count: createExpression(
      typeof defaults.count !== 'boolean' && defaults.count
        ? defaults.count
        : 1,
    ),
    id: createId(defaults, regenerateIds),
  });
};

/**
 * Create an IBuildingVersion
 * @param defaults Partial with overrides of the default values
 * @param regenerateId how ids should be generated. Will regenerate by default.
 * @returns
 */
const createVersion = (
  defaults: Partial<IFactoryVersion>,
  regenerateIds?: RegenerateIds,
): IBuildingVersion => {
  const mainCategoryElements = mainCategoryIds.map((id) =>
    createElementFromPartial(
      {
        kind: ElementKind.Element,
        category_id: id,
        fallbackName: getElementCategoryById(id).name,
      },
      regenerateIds,
    ),
  );

  const elements = defaults?.elements?.length
    ? createChildElements(defaults, regenerateIds)
    : mainCategoryElements;

  const idMap = getElementIdMap(defaults.elements, elements, regenerateIds);

  return {
    kind: ElementKind.Version,
    name: 'Version',
    products: {},
    ...omitUndefined(defaults),
    properties: createElementProperties(defaults.properties, regenerateIds),
    proposals: createProposals(defaults.proposals, idMap),
    id: createId(defaults, regenerateIds),
    elements,
  };
};

/**
 * Create an array of proposals.
 * Will take care of making sure names are unique
 * and that at least one proposal is active
 * @param proposalDefaults An array of partials with overrides of the default values for each proposal
 * @param idMap
 * @returns
 */
export const createProposals = (
  proposalDefaults: Partial<IProposal>[] | undefined,
  idMap?: IElementVersionMap,
): IProposal[] => {
  // If no proposals are passed, create a default one
  if (!proposalDefaults?.length) {
    return [createProposal({ active: true }, idMap)];
  }
  const names: Array<string | undefined> = [];
  const haveActive = proposalDefaults.some((p) => p.active);
  return proposalDefaults.map((p, i) => {
    // Make names unique
    const name = findFreeName(names, DEFAULT_PROPOSAL_NAME);
    names.push(name);

    // If no proposal is active, make the first the active one
    const active = haveActive ? p.active : i === 0;

    return createProposal({ ...p, name, active }, idMap);
  });
};

/**
 * Map of version ids and element ids. Used when duplicating elements
 */
export interface IElementVersionMap {
  versionMap: Map<IElement['versionId'], IElement['versionId']>;
  elementMap: Map<IElement['id'], IElement['id']>;
  activeElementVersionRecord?: IProposal['selections'];
}

/**
 * Will take the list of original elements and the newly created elements
 * and create a map of version ids and element ids (from old id to new id).
 * Used when duplicating elements.
 * @param prev The original elements
 * @param next The newly created elements
 * @returns
 */
export const getElementIdMap = (
  prev: OneOfChildFactoryElements[] = [],
  next: OneOfElementListChildren[] = [],
  regenerateIds?: RegenerateIds,
):
  | RequireProperties<IElementVersionMap, 'activeElementVersionRecord'>
  | undefined => {
  if (!shouldRegenerateIds(regenerateIds)) {
    return undefined;
  }
  const versionMap: Map<IElement['versionId'], IElement['versionId']> =
    new Map();
  const elementMap: Map<IElement['id'], IElement['id']> = new Map();
  const activeElementVersionRecord: IProposal['selections'] = {};

  const validRootElements = prev.filter(
    (e) => isElement(e) && hasDefinedProperties(e, 'id', 'unit', 'count'),
  ) as IElement[];

  const prevElements = flattenElements(...validRootElements);
  const nextElements = flattenElements(...next);

  // If a version is duplicated length should be the same to
  if (prevElements.length !== nextElements.length) {
    return;
  }

  for (let i = 0; i < prevElements.length; i++) {
    const prevElement = prevElements[i];
    const nextElement = nextElements[i];

    const prevVersionId = getElementVersionId(prevElement);
    const nextVersionId = getElementVersionId(nextElement);

    // Remap if id is different
    if (prevElement && nextElement && prevElement.id !== nextElement.id) {
      elementMap.set(prevElement.id, nextElement.id);
    }

    // Remap if version id is different
    if (prevVersionId && nextVersionId && prevVersionId !== nextVersionId) {
      versionMap.set(prevVersionId, nextVersionId);
    }

    // Remember which element versions that are active
    if (
      isElementVersionElement(nextElement) &&
      isActiveElementVersion(nextElement)
    ) {
      activeElementVersionRecord[nextElement.versionId] = nextElement.id;
    }
  }
  return versionMap.size ||
    elementMap.size ||
    Object.keys(activeElementVersionRecord).length
    ? { versionMap, elementMap, activeElementVersionRecord }
    : undefined;
};

/**
 * Create an element
 * @param defaults Partial with overrides of the default values
 * @param regenerateId how ids should be generated. Will regenerate by default.
 * @returns
 */
export const createElement = (
  defaults: Partial<IFactoryElement> = {},
  regenerateId?: RegenerateIds,
): IElement => {
  // Omit undefined values so they don't override values by mistake
  defaults = omitUndefined(defaults);

  const regenerateBool = shouldRegenerateIds(regenerateId);

  // Default value for count, provide only if unit is passed
  const element: IElement = {
    kind: ElementKind.Element,
    name: '',
    ...defaults,
    ...createCountAndUnit(defaults),
    id: createId(defaults, regenerateId),
    quantity: createElementQuantityRecord(defaults.quantity, regenerateBool),
    count: createExpression(
      typeof defaults.count !== 'boolean' && defaults.count
        ? defaults.count
        : 1,
    ),
    properties: createElementProperties(defaults.properties, regenerateBool),
    elements: createChildElements(defaults, regenerateId),
  };

  // Make sure element is valid before returning
  return element;
};

/**
 * Create a new element of a specific kind.
 * Can also be used to shallow clone an element.
 * @param kind Type of element which should be created
 * @param defaults A partial with overides of the default values
 * @param regenerateId If true this will always return element with a new id
 * @returns
 */
export function createElementOfType<T extends keyof ElementKindMap>(
  kind: T,
  defaults: Partial<FactoryKindMap[T]> = {},
  regenerateIds?: RegenerateIds,
): CreatedElement<T> {
  switch (kind) {
    case ElementKind.Version: {
      return createVersion(
        defaults as Partial<IFactoryVersion>,
        regenerateIds,
      ) as CreatedElement<T>;
    }
    case ElementKind.Element: {
      return createElement(
        defaults as Partial<IFactoryElement>,
        regenerateIds,
      ) as CreatedElement<T>;
    }
    case ElementKind.Product: {
      return createProductElement(
        defaults as Partial<IFactoryProductElement>,
        regenerateIds,
      ) as CreatedElement<T>;
    }
  }
  throw new Error(`Can't create element of type ${kind}`);
}

/**
 * Create a new element of a specific kind.
 * Can also be used to shallow clone an element.
 * @param initialValues A partial with overides of the default values. Need to contain kind
 * @param regenerateId If true this will always return element with a new id
 * @returns
 */
export function createElementFromPartial<T extends OneOfFactoryElements>(
  initialValues: T,
  regenerateId?: RegenerateIds,
): CreatedElement<T['kind']> {
  return createElementOfType(
    initialValues.kind,
    initialValues,
    regenerateId,
  ) as CreatedElement<T['kind']>;
}

export type OneOfChildFactoryElements =
  | IFactoryElement
  | IFactoryProductElement;

export type IFactoryElement = SemiPartial<
  Replace<
    IElement,
    {
      elements: OneOfChildFactoryElements[];
      properties: IFactoryProperty[];
      count: FactoryCountExpression;
      quantity: FactoryQuantityRecord;
    }
  >,
  'kind'
>;

/**
 * Add element to parent. TODO: Rewrite addElements in project_helpers.ts to support this
 * @param parent
 * @param initialValues One or more elements to be added
 * @returns The modified parent
 */
export const addElementsToParent = <R extends OneOfParentElements>(
  parent: OneOfParentElements,
  initialValues: ArrayOrSingle<OneOfChildFactoryElements>,
  regenerateId = true,
): R => {
  const originalId = 'id' in initialValues ? initialValues.id : undefined;
  const elements = getChildElements(parent);
  const original = originalId
    ? elements.find((e) => e.id === originalId)
    : undefined;
  const newElements = createElements(asArray(initialValues), regenerateId);
  return {
    ...parent,
    // elements: [...elements, ...newElements],

    elements: sortInsert<OneOfElementListChildren>(
      'after',
      elements,
      original,
      ...newElements,
    ),
  } as R;
};

/**
 * Partial version of IBuildingVersion that can be used to create a new version.
 */
export type IFactoryVersion = SemiPartial<
  Replace<
    IBuildingVersion,
    {
      elements: OneOfChildFactoryElements[];
      proposals?: Partial<IProposal>[];
      properties: IFactoryProperty[];
    }
  >,
  'kind'
>;

/**
 * All possible partials to be used when creating new elements
 */
export type OneOfFactoryElements = IFactoryVersion | OneOfChildFactoryElements;

/**
 * Use FactoryKindMap to get correct type of elements.
 * Maps element kind to the corresponding factory type.
 */
type FactoryKindMap = {
  [ElementKind.Product]: IFactoryProductElement;
  [ElementKind.Element]: IFactoryElement;
  [ElementKind.Version]: IFactoryVersion;
};

/**
 * Check if element is a factory product element
 * @param element
 * @returns
 */
export const isFactoryProductElement = (
  element?: OneOfFactoryElements,
): element is IFactoryProductElement => {
  return getElementKind(element) === ElementKind.Product;
};

/**
 * Check if element is a factory version
 * @param element
 * @returns
 */
export const isFactoryVersion = (
  element?: OneOfFactoryElements,
): element is IFactoryVersion => {
  return getElementKind(element) === ElementKind.Version;
};

/**
 * Check if element is a factory element
 * @param element
 * @returns
 */
export const isFactoryElement = (
  element?: OneOfFactoryElements,
): element is IFactoryElement => {
  return getElementKind(element) === ElementKind.Element;
};

/**
 * Check if element is a factory element or a factory product element
 * @param element
 * @returns
 */
export const isOneOfChildFactoryElements = (
  element?: OneOfFactoryElements,
): element is IFactoryProductElement | IFactoryElement => {
  return isFactoryElement(element) || isFactoryProductElement(element);
};
