import React, { useCallback, useMemo, useState } from 'react';
import ProjectKebabMenu from './ProjectKebabMenu';
import { enqueueSnackbar } from 'notistack';
import { getProject, useUpdateProject } from '../../../../store/project';
import { Download, Edit, Refresh } from '@mui/icons-material';
import amplitudeLog from '../../../../amplitude';
import { useForceUpdateProductsInProjectCallback } from '../../../../hooks/useProducts';
import { projectActionMenuIconStyles } from '../../../../style/constants';
import { useIsReadonly, useUserIsOwner } from '../../../../hooks/user.hook';
import { useBooleanState } from '../../../../hooks/hooks';
import { useImportElements } from '../../../../hooks/import.hook';
import ImportElementsDialog from '../../../ImportElements/ImportElementsDialog';
import { useUIState } from '../../../../store/ui';
import { FileWithPath } from 'react-dropzone/.';
import { ProjectMenuProps } from '../../menu.model';

const ProjectHeaderKebabMenu: React.FC = () => {
  const { setShowProjectDetailsEditor } = useUIState(
    'setShowProjectDetailsEditor',
  );
  const project = getProject();
  const readonly = useIsReadonly();
  const isOwner = useUserIsOwner(project.id);

  const importElements = useImportElements();
  const updateProject = useUpdateProject();
  const updateProductsInProjectCallback =
    useForceUpdateProductsInProjectCallback();

  const [importDialogOpen, openImportDialog, closeImportDialog] =
    useBooleanState(false);

  const [kebabMenuAnchor, setKebabMenuAnchor] = useState<Element>();

  const handleImport = useCallback(
    async (files: readonly FileWithPath[]): Promise<void> => {
      await importElements(files);
      closeImportDialog();
      setKebabMenuAnchor(undefined);
    },
    [importElements, closeImportDialog],
  );

  const updateProducts = useCallback(async () => {
    const updatedProject = updateProductsInProjectCallback(project);

    if (updatedProject !== project) {
      await updateProject(updatedProject, {
        successMessage: `Products updated in "${project.name}"`,
        errorMessage: `Failed to update products in "${project.name}"`,
        logError: true,
      });
      amplitudeLog('Project Products Update', {
        ProjectId: project.id,
      });
    } else {
      enqueueSnackbar('Products are already up to date', {
        variant: 'info',
      });
    }
    setKebabMenuAnchor(undefined);
  }, [project, updateProductsInProjectCallback, updateProject]);

  const renameProject = useCallback(() => {
    amplitudeLog('Project Rename', {
      ProjectId: project.id,
    });
    setShowProjectDetailsEditor(true);
    setKebabMenuAnchor(undefined);
  }, [project.id, setShowProjectDetailsEditor]);

  const closeKebabMenu = useCallback(() => {
    setKebabMenuAnchor(undefined);
  }, []);

  const extraItems = useMemo<ProjectMenuProps[]>(() => {
    const disabled = (!isOwner && !!project.locked) || !!project.archived;

    return [
      {
        id: 'import',
        label: 'Import elements',
        icon: <Download {...projectActionMenuIconStyles} />,
        disabled: readonly,
        placement: 'export',
        onClick: () => openImportDialog(),
      },
      {
        id: 'update_products',
        label: 'Update products',
        icon: <Refresh {...projectActionMenuIconStyles} />,
        disabled,
        tooltip: disabled
          ? 'Products cannot be updated in readonly projects'
          : '',
        onClick: updateProducts,
      },
      {
        id: 'rename',
        label: 'Rename',
        placement: 0,
        icon: <Edit {...projectActionMenuIconStyles} />,
        onClick: renameProject,
      },
    ];
  }, [
    renameProject,
    updateProducts,
    isOwner,
    openImportDialog,
    project.archived,
    project.locked,
    readonly,
  ]);

  return (
    !!project.id && (
      <>
        <ProjectKebabMenu
          projectId={project.id}
          projectName={project.name}
          locked={!!project.locked}
          archived={!!project.archived}
          extraItems={extraItems}
          verticalKebabIcon
          anchor={kebabMenuAnchor}
          onOpen={setKebabMenuAnchor}
          onClose={closeKebabMenu}
        />
        <ImportElementsDialog
          title="Import elements"
          content="Drag a CSV, Excel or Bidcon file here to import its elements"
          open={importDialogOpen && !readonly}
          onImport={handleImport}
          onClose={closeImportDialog}
        />
      </>
    )
  );
};

export default ProjectHeaderKebabMenu;
